export default {
  path: '/knowledge',
  component: () => import(/* webpackChunkName: 'knowledge' */'@/views/knowledge/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'knowledge',
      component: () => import(/* webpackChunkName: 'knowledgeIndex' */'@/views/knowledge/index')
    },
  ]
}