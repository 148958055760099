import moment from "moment"
export const dateFormat2 = function (dateinit) {
  console.log(dateinit, 'dateinit')

  let formatStr = 'YYYY-MM-DD'
  // if (dateinit === null || dateinit === 0 || dateinit === '' || dateinit === undefined) {
  //   return ''
  // }
  // const date = new Date(dateinit * 1000)
  // const dateStr = {
  //   'y+': date.getFullYear(), // 年
  //   'm+': date.getMonth() + 1, // 月份
  //   'd+': date.getDate(), // 日
  //   'h+': date.getHours(), // 小时
  //   'i+': date.getMinutes(), // 分
  //   's+': date.getSeconds() // 秒
  // }
  // for (const item in dateStr) {
  //   if (new RegExp('(' + item + ')', 'i').test(formatStr)) {
  //     formatStr = formatStr.replace(
  //       RegExp.$1,
  //       dateStr[item].toString().length < 2 ? '0' + dateStr[item] : dateStr[item]
  //     )
  //   }
  // }
  formatStr = moment(dateinit).format(formatStr) 
  console.log(formatStr, 'formatStrformatStr')
  return formatStr
}
