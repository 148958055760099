export default {
  path: '/crm',
  component: () => import(/* webpackChunkName: 'crm' */'@/views/crm/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'my',
      component: () => import(/* webpackChunkName: 'mycrm' */'@/views/crm/index')
    },
    {
      path: '/crm/templates',
      name: 'templates',
      component: () => import(/* webpackChunkName: 'templates' */'@/views/crm/templates/index')
    },
    {
      path: '/crm/templates/add',
      name: 'addTemplates',
      component: () => import(/* webpackChunkName: 'addTemplates' */'@/views/crm/templates/add/index')
    },
    {
      path: '/crm/add',
      name: 'addQuote',
      component: () => import(/* webpackChunkName: 'addQuote' */'@/views/crm/add/index')
    },
    {
      path: '/crm/offer',
      name: 'crmOffer',
      component: () => import(/* webpackChunkName: 'offer' */'@/views/crm/offer/index')
    },
    {
      path: '/crm/dempartment',
      name: 'crmDempartment',
      component: () => import(/* webpackChunkName: 'dempartment' */'@/views/crm/dempartment/index')
    },
    {
      path: '/crm/position/price',
      name: 'crmPositionPrice',
      component: () => import(/* webpackChunkName: 'price' */'@/views/crm/position/price')
    }
    ,
    {
      path: '/crm/position/fixedCost',
      name: 'crmPositionFixedCost',
      component: () => import(/* webpackChunkName: 'fixedCost' */'@/views/crm/position/fixedCost')
    },
    {
      path: '/crm/project/comparison',
      name: 'comparison',
      component: () => import(/* webpackChunkName: 'comparison' */'@/views/crm/project/comparison')
    }
    ,
    {
      path: '/crm/project/profit',
      name: 'profit',
      component: () => import(/* webpackChunkName: 'profit' */'@/views/crm/project/profit')
    },
    {
      path: '/crm/project/wbs',
      name: 'wbs',
      component: () => import(/* webpackChunkName: 'wbs' */'@/views/crm/project/wbs')
    }
  ]
}