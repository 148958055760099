export default {
  path: '/guideline',
  component: () => import(/* webpackChunkName: 'guideline' */'@/views/guideline/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'guideline',
      component: () => import(/* webpackChunkName: 'guidelineIndex' */'@/views/guideline/index')
    },
    {
      path: '/guideline/type',
      name: 'guidelineType',
      component: () => import(/* webpackChunkName: 'guidelineInfo' */'@/views/guideline/type')
    },
    {
      path: '/guideline/bulletin',
      name: 'guidelineBulletin',
      component: () => import(/* webpackChunkName: 'guidelineInfo' */'@/views/guideline/bulletin')
    },
  ]
}