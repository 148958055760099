export default {
  path: '/system',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/system/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'user',
      component: () => import(/* webpackChunkName: 'user' */'@/views/system/index')
    },
    {
      path: '/system/role',
      name: 'role',
      component: () => import(/* webpackChunkName: 'role' */'@/views/system/role/index')
    },
    {
      path: '/system/menu',
      name: 'menu',
      component: () => import(/* webpackChunkName: 'menu' */'@/views/system/menu/index')
    }
  ]
}