import localStorage from '@/libs/localStorage'

export default {
	state: {
		token: localStorage.token, // 用户token
		remember: localStorage.remember, // 记住账号密码
		userInfo: localStorage.userInfo, // 当前用户信息
		menuUri: localStorage.menuUri, // 当前登录用户可以访问哪些页面
		menuList: [],  // 工作台有哪些菜单
		ctms: []  //ctms权限
	},
	mutations: {
		setToken (state, payload) {
			localStorage.token = payload
			state.token = JSON.parse(payload)
		},
		setRemember (state, payload) {
			localStorage.remember = payload
			state.remember = JSON.parse(payload)
		},
		setUserInfo (state, payload) {
			localStorage.userInfo = payload
			state.userInfo = JSON.parse(payload)
		},
		setMenuUri (state, payload) {
			localStorage.menuUri = payload
			state.menuUri = JSON.parse(payload)
		},
		setMenuList (state, payload) {
			localStorage.menuList = payload
			state.menuList = JSON.parse(payload)
		},
		setCtms (state, payload) {
			localStorage.ctms = payload
			state.ctms = payload
		}
	},
	actions: {

	}
}
