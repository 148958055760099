export default {
  path: '/storage3',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/pan3/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'file',
      component: () => import(/* webpackChunkName: 'file' */'@/views/pan3/index')
    },
    {
      path: '/storage3/recycle',
      name: 'recycle',
      component: () => import(/* webpackChunkName: 'recycle' */'@/views/pan3/recycle/index')
    },
    {
      path: '/storage3/log',
      name: 'log',
      component: () => import(/* webpackChunkName: 'log' */'@/views/pan3/slog/index')
    },
    {
      path: '/storage3/folder',
      name: 'folder',
      component: () => import(/* webpackChunkName: 'folder' */'@/views/pan3/folder/index')
    },
    {
      path: '/storage3/group',
      name: 'group',
      component: () => import(/* webpackChunkName: 'group' */'@/views/pan3/group/index')
    },
    {
      path: '/storage3/downloadTask',
      name: 'downloadTask',
      component: () => import(/* webpackChunkName: 'group' */'@/views/pan3/downloadTask/index')
    }
  ]
}