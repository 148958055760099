export default {
  path: '/storage',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/pan/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'file',
      component: () => import(/* webpackChunkName: 'file' */'@/views/pan/index')
    },
    {
      path: '/storage/recycle',
      name: 'recycle',
      component: () => import(/* webpackChunkName: 'recycle' */'@/views/pan/recycle/index')
    },
    {
      path: '/storage/log',
      name: 'log',
      component: () => import(/* webpackChunkName: 'log' */'@/views/pan/slog/index')
    },
    {
      path: '/storage/folder',
      name: 'folder',
      component: () => import(/* webpackChunkName: 'folder' */'@/views/pan/folder/index')
    },
    {
      path: '/storage/group',
      name: 'group',
      component: () => import(/* webpackChunkName: 'group' */'@/views/pan/group/index')
    },
    {
      path: '/storage/downloadTask',
      name: 'downloadTask',
      component: () => import(/* webpackChunkName: 'group' */'@/views/pan/downloadTask/index')
    }
  ]
}