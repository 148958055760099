<template>
	<el-dialog
		title="设置您的登录密码"
		:visible.sync="visible"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
	>
		<el-form
			:model="ruleForm"
			status-icon
			:rules="rules"
			ref="ruleForm"
			label-width="100px"
		>
			<el-form-item label="账号">
				<span>{{ username }}</span>
			</el-form-item>
			<el-form-item label="输入密码" prop="password">
				<el-input
					type="password"
					v-model="ruleForm.password"
					show-password
				></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="checkPass">
				<el-input
					type="password"
					v-model="ruleForm.checkPass"
					show-password
				></el-input>
			</el-form-item>
			<el-form-item label="">
				<span style="color: #999999"
					>密码格式为8-20位，必须包含数学，大小写字母</span
				>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button v-if="isClose" @click="visible = false">关 闭</el-button>
			<el-button type="primary" @click="submitForm" :loading="isloading"
				>确 定</el-button
			>
		</div>
	</el-dialog>
</template>

<script>
import { changePassword } from '@/services/user'
export default {
  name: 'setPassword',
  data () {
    const passValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!new RegExp('^(?![0-9]+$)(?!a-zA-Z]+$)[0-9A-Za-z]{' + 8 + ',' + 20 + '}$').test(value)) {
        callback(new Error('密码格式为8-20位，必须包含数学，大小写字母'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const checkPassValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value.length < 8) {
        callback(new Error('密码至少8位'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      ruleForm: {
        password: '',
        checkPass: ''
      },
      rules: {
        password: [{ validator: passValidator, trigger: 'blur' }],
        checkPass: [{ validator: checkPassValidator, trigger: 'blur' }]
      },
      isloading: false,
    }
  },
  methods: {
    async submitForm () {
      await this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.isloading = true
          await changePassword(this.ruleForm)
          this.isloading = false
          this.visible = false
          this.callback({ done: true })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>