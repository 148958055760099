import Vue from 'vue'
// 导入组件
import logoutDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个“子类”
const LogoutDetailConstructor = Vue.extend(logoutDialog)

let logoutInstance = null
/**
 * 文件详情文件 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
export default (obj) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (logoutInstance !== null) {
    document.body.removeChild(logoutInstance.$el)
  }
  // type true 代表应用， false 代表首页
  let { type } = obj
  return new Promise((reslove) => {
    logoutInstance = new LogoutDetailConstructor({
      el: document.createElement('div'),
      data () {
        return {
          type
        }
      }
    })
    logoutInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res.done && logoutInstance !== null) {
        document.body.removeChild(logoutInstance.$el)
        logoutInstance = null
      }
    }
    document.body.appendChild(logoutInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      logoutInstance.visible = true //  打开对话框
    })
  })
}
