export default {
  path: '/talent',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/talent/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'talent',
      component: () => import(/* webpackChunkName: 'user' */'@/views/talent/index')
    }
  ]
}