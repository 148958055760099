<template>
	<el-dialog
		:title="title"
		center
		:modal="false"
		:lock-scroll="false"
		:close-on-press-escape="false"
		:show-close="false"
		:destroy-on-close="true"
		:visible.sync="visible"
		fullscreen
		:top="'5vh'"
	>
		<div class="content">
			<div class="left">
				<p>选择人员：</p>
				<br />
				<el-input placeholder="输入人名进行搜索" v-model="u_filterText">
				</el-input>
				<p>注意1：此数据并不会及时更新，离职及入职人员更新会有24小时延迟！</p>
				<p>注意2：不同部门里的同一个用户不需要重复勾选，重复勾选无效！</p>
				<br />
				<el-tree
					:data="u_data"
					ref="u_tree"
					:props="u_defaultProps"
					show-checkbox
					v-loading="u_loading"
					@check="u_handleCheckChange"
					node-key="id"
					:default-expanded-keys="u_dExpandedKeys"
					:default-checked-keys="u_dCheckedKeys"
					:filter-node-method="u_filterNode"
				>
					<span class="custom-tree-node" slot-scope="{ data }">
						<span style="margin-right: 15px">{{ data.name }}</span>
						<el-tag
							style="margin-right: 15px"
							v-if="data.position"
							size="mini"
							>{{ data.position }}</el-tag
						>
						<el-tag v-if="data.leader" size="mini">主管</el-tag>
					</span>
				</el-tree>
			</div>
			<div class="right">
				<p>已选人员：</p>
				<br />
				<TablePage>
					<template #table>
						<el-table
							border
							:data="u_tableData"
							style="width: 100%"
							height="100%"
						>
							<el-table-column prop="name" label="姓名"> </el-table-column>
						</el-table>
					</template>
				</TablePage>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium"
				>取消，不做任何修改</el-button
			>
			<el-button
				type="primary"
				size="medium"
				v-loading="btnLoad"
				@click="saveBtn"
				>保 存 修 改</el-button
			>
		</span>
	</el-dialog>
</template>

<script>
import TablePage from '_c/tablePage';
import { departmentMember, departmentSaveDepartmentMember } from '_s/crm';
export default {
  components: {
    TablePage
  },
  data () {
    return {
      visible: false,
      u_data: [],
      u_defaultProps: {
        children: 'children',
        label: 'name'
      },
      u_loading: false,
      u_tableData: [],
      u_filterText: '',
      u_dCheckedKeys: [],
      u_dExpandedKeys: ['p1'],
      isChangeTree: false,
      btnLoad: false
    }
  },
  watch: {
    u_filterText (val) {
      this.$refs.u_tree.filter(val);
    }
  },
  methods: {
    u_filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    async saveBtn () {
      this.btnLoad = true
      const data = await departmentSaveDepartmentMember({
        department_id: this.id,
        uids: this.u_tableData
      })
      if (data.code === 0) {
        this.visible = false
      }
      this.btnLoad = false
    },
    async u_showUserList () {
      this.u_loading = true
      const { data } = await departmentMember({ department_id: this.id })
      this.u_data = data.list
      const playLoad = data.playLoad.map(i => i.id.toString())
      this.u_dCheckedKeys = playLoad
      this.u_dExpandedKeys = [...this.u_dExpandedKeys, ...playLoad]
      this.u_tableData = data.playLoad
      this.u_loading = false
    },
    u_handleCheckChange (node, nodeData) {
      this.isChangeTree = true
      this.u_tableData = nodeData.checkedNodes.map(i => {
        if (!i.id.startsWith('p')) {
          return { id: i.id, name: i.name, leaderUid: i.leaderUid, leaderName: i.leaderName, department: i.department }
        }
      }).filter(Boolean)
    }
  },
  created () {
    this.u_showUserList()
  }
}
</script>
<style lang="scss" scoped>
:deep(.el-dialog__body) {
	padding: 0;
	height: 85vh;
}

:deep(.el-dialog__footer) {
	padding: 20px;
}

.content {
	display: flex;
	height: 100%;

	.left {
		word-break: break-all;
		padding: 20px;
		width: 50%;
		border-right: 1px solid #999999;
		border-top: 1px solid #999999;
		border-bottom: 1px solid #999999;
		text-overflow: ellipsis;
		overflow: auto;
	}

	.right {
		word-break: break-all;
		padding: 20px;
		width: 50%;
		border-top: 1px solid #999999;
		border-bottom: 1px solid #999999;
		display: flex;
		flex-direction: column;
	}
}
</style>