<template>
	<div>
		<!-- 下载文件进度弹窗 -->
		<el-dialog
			title="正在下载，请等待"
			:visible.sync="visible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			width="50%"
		>
			<div style="text-align: center">
				<el-progress type="circle" :percentage="percentage"></el-progress>
			</div>
			<br />
			<p>{{ fileInfo.fileName }}</p>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="downClose" :loading="loading"
					>取消下载</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import axios from 'axios'
import { medDRAExportCode, whoDDExportCode } from '_s/medicode.js'
export default {
  name: 'DownLoadFileDialog',
  data () {
    return {
      percentage: 0, //进度条的百分比
      source: {}, //取消下载时的资源对象
      loading: true,
      visible: false //  对话框是否可见
    }
  },
  methods: {
    downClose () {
      this.source.cancel('log==客户手动取消下载')
    },
    initHttp () {
      var _this = this
      //取消时的资源标记
      this.source = axios.CancelToken.source()
      const data = { id: this.fileInfo.id }
      const callb = function (ProgressEvent) {
        const load = ProgressEvent.loaded
        const total = ProgressEvent.total
        const progress = (load / total) * 100
        if (progress > _this.percentage) {
          _this.percentage = Math.floor(progress)
        }
        if (progress == 100) {
          //下载完成
          _this.visible = false
        }
      }
      if (this.type === 'meddra') return medDRAExportCode(data, callb, this.source.token)
      if (this.type === 'whodd') return whoDDExportCode(data, callb, this.source.token)
    }
  },
  mounted () {
    this.percentage = 0
    this.loading = false
    this.initHttp().then(res => {
      this.visible = false
      const content = res.data
      if (content.size == 0) {
        this.loadClose()
        this.$alert("下载失败")
        return
      }
      const blob = new Blob([content])
      const fileName = res.fileName.replace(new RegExp('"', "g"), '')
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a")
        elink.download = fileName
        elink.style.display = "none"
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        setTimeout(function () {
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        }, 100)
      } else {
        navigator.msSaveBlob(blob, fileName)
      }
    }).catch(error => {
      this.visible = false
      console.info(error)
    })
  }
}
</script>

<style lang="scss" scoped></style>