<template>
	<!-- 下载文件进度弹窗 -->
	<el-dialog
		title="正在下载，请等待"
		:visible.sync="visible"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
		width="50%"
	>
		<div style="text-align: center">
			<el-progress type="circle" :percentage="percentage"></el-progress>
		</div>
		<br />
		<p>
			{{ fileInfo.file_name }} |
			<span style="color: #999999; font-size: 10px">{{
				$file2.calculateFileSize(fileInfo.file_size)
			}}</span>
		</p>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="downClose" :loading="loading"
				>取消下载</el-button
			>
		</div>
	</el-dialog>
</template>

<script>
import axios from 'axios'
import { downloadFile } from '_s/fileStorage2.js'
export default {
  name: 'DownLoadFileDialog',
  data () {
    return {
      percentage: 0, //进度条的百分比
      source: {}, //取消下载时的资源对象
      loading: true,
      visible: false //  对话框是否可见
    }
  },
  methods: {
    downClose () {
      this.source.cancel('log==客户手动取消下载')
    },
    initHttp () {
      var _this = this
      //取消时的资源标记
      this.source = axios.CancelToken.source()
      return downloadFile(
        { id: this.fileInfo.id },
        function (ProgressEvent) {
          const load = ProgressEvent.loaded
          const total = ProgressEvent.total
          const progress = (load / total) * 100
          //一开始已经在计算了 这里要超过先前的计算才能继续往下
          if (progress > _this.percentage) {
            _this.percentage = Math.floor(progress)
          }
          if (progress == 100) {
            //下载完成
            _this.visible = false
          }
        },
        this.source.token,//声明一个取消请求token
      )
    }
  },
  mounted () {
    this.percentage = 0
    this.loading = false
    this.initHttp().then(res => {
      this.visible = false
      const content = res
      if (!content && content.data.size == 0) {
        this.loadClose()
        this.$alert("下载失败")
        return
      }
      const blob = new Blob([content.data])
      const fileName = content.fileName
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a")
        elink.download = fileName
        elink.style.display = "none"
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        setTimeout(function () {
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        }, 100)
      } else {
        navigator.msSaveBlob(blob, fileName)
      }
    }).catch(error => {
      this.visible = false
      console.info(error)
    })
  }
}
</script>

<style lang="scss" scoped></style>