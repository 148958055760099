import Vue from 'vue'
// 导入组件
import QxFileDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个 "子类"
const QxFileConstructor = Vue.extend(QxFileDialog)

let qxFileInstance = null
/**
 * 初始化下载文件进度条
 * @param {object | array} fileInfo 文件信息
 */
const initInstanceFileDetail = (fileInfo) => {
  qxFileInstance = new QxFileConstructor({
    el: document.createElement('div'),
    data () {
      return {
        fileInfo
      }
    }
  })
}
/**
 * 文件详情文件 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
const qxFileDialog = (obj) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (qxFileInstance !== null) {
    document.body.removeChild(qxFileInstance.$el)
  }
  let { fileInfo } = obj
  return new Promise((reslove) => {
    initInstanceFileDetail(fileInfo)
    qxFileInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res === 'cancel' && qxFileInstance !== null) {
        document.body.removeChild(qxFileInstance.$el)
        qxFileInstance = null
      }
    }
    document.body.appendChild(qxFileInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      qxFileInstance.visible = true //  打开对话框
    })
  })
}

export default qxFileDialog
