export default {
  path: '/myExpress',
  component: () => import(/* webpackChunkName: 'myExpress' */'@/views/myExpress/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'myExpress',
      component: () => import(/* webpackChunkName: 'myExpressIndex' */'@/views/myExpress/index')
    },
    {
      path: '/myExpress/address',
      name: 'myExpressAddress',
      component: () => import(/* webpackChunkName: 'myExpressAddress' */'@/views/myExpress/address')
    },
    {
      path: '/myExpress/courier',
      name: 'myExpresscourier',
      component: () => import(/* webpackChunkName: 'myExpressAddress' */'@/views/myExpress/courier')
    }
  ]
}