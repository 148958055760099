<template>
	<!-- 新建文件夹对话框 -->
	<el-dialog
		:title="type === 1 ? '新建文件夹' : `编辑文件夹：${name}`"
		:visible.sync="visible"
		:close-on-click-modal="false"
		width="580px"
		@close="handleDialogClose"
		:destroy-on-close="true"
	>
		<el-form
			class="add-folder-form"
			:model="form"
			:rules="formRules"
			ref="addFolderForm"
			label-width="100px"
			label-position="top"
		>
			<el-form-item label="上级文件夹" prop="name">
				<span style="font-weight: 700" v-if="showCascader">{{
					parentName
				}}</span>
				<el-cascader
          v-else
					:props="props"
					:options="options"
					v-model="parentId"
					style="width: 100%"
					:placeholder="parentName"
					:show-all-levels="false"
					@visible-change="bindEvent"
					@expand-change="bindEvent"
					ref="elCascader"
				></el-cascader>
			</el-form-item>
			<el-form-item label="文件夹名称" prop="folder_name">
				<el-input
					v-model="form.folder_name"
					placeholder="请输入文件夹名称"
					type="textarea"
					:autosize="{ minRows: 3, maxRows: 3 }"
					maxlength="255"
					show-word-limit
					@keydown.enter.native.prevent
				></el-input>
			</el-form-item>
			<el-form-item label="排序" prop="order">
				<el-input-number v-model="form.order" :min="0"></el-input-number>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<div class="tips">
				<span v-if="showCascader && type === 1"
					>提示：新建文件夹的权限继承父级文件夹的权限</span
				>
			</div>
			<div class="btns">
				<el-button @click="handleDialogClose">取 消</el-button>
				<el-button
					type="primary"
					:loading="sureBtnLoading"
					@click="handleDialogSure()"
					>确 定</el-button
				>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import { getFileList, newFolder, changeFolder } from '_s/fileStorage2.js';
export default {
  name: 'AddFolderDialog',
  data () {
    const validateFileName = (rule, value, callback) => {
      const fileNameReg = new RegExp(`[\\\\/:*?"<>|]`)
      if (value && fileNameReg.test(value)) {
        callback(new Error(`文件夹名称不能包含下列任何字符：\\/:*?"<>|`))
      } else {
        callback()
      }
    }
    return {
      oldParentId: -1, // 存放刚开始的父id，判断是否一致，全部刷新还是局部刷新
      options: [{ value: 0, label: '根目录', leaf: false }],
      visible: false, //  对话框是否可见
      form: {
        folder_name: '',
        order: 0,
      },
      formRules: {
        folder_name: [
          { required: true, message: '请输入文件夹名称', trigger: 'blur' },
          { validator: validateFileName, trigger: ['blur', 'change'] }
        ]
      },
      sureBtnLoading: false,
      props: {
        emitPath: false,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          getFileList({ folder_id: node.value }).then(res => {
            console.log(res,'res')
            let arr = []
            res.data.list.forEach((item) => {
              if (item.is_dir) {
                arr.push(item)
              }
            })
            const node = arr.map(item => ({ value: item.id, label: item.file_name, leaf: false }))
            resolve(node)
          })
        }
      }
    }
  },
  watch: {
    /**
     * 监听对话框打开、关闭状态
     */
    visible (newValue) {
      if (newValue) {
        // 打开时绑定回车事件
        document.addEventListener('keyup', this.handleAddKeyupEnter)
      } else {
        // 关闭时移除回车事件
        document.removeEventListener('keyup', this.handleAddKeyupEnter)
      }
    }
  },
  methods: {
    /**
     * DOM 绑定回车事件
     * @description 回车触发新增文件夹事件
     * @param {event} event 事件
     */
    handleAddKeyupEnter (event) {
      if (event.key === 'Enter') {
        this.handleDialogSure('addFolderForm')
      }
    },
    /**
     * 取消按钮点击事件 & 对话框关闭的回调
     * @description 关闭对话框，重置表单
     */
    handleDialogClose () {
      this.$refs['addFolderForm'].resetFields()
      this.visible = false
      this.callback({ done: false })
    },
    /**
     * 确定按钮点击事件
     * @description 校验表单，校验通过后调用新建文件夹接口
     */
    async handleDialogSure () {
      this.sureBtnLoading = true
      let data = {}
      if (this.type === 1) {
        // 新增文件夹
        data = await newFolder({ ...this.form, parent_id: this.parentId })
      } else {
        // 修改文件夹
        if (this.parentId === this.folderId) {
          this.sureBtnLoading = false
          return this.$message.error('错误，上级文件夹不能是自己！')
        }
        data = await changeFolder({ ...this.form, parent_id: this.parentId, folder_id: this.folderId })
      }
      if (data.code === 0) {
        this.visible = false
        this.callback({ done: true, refresh: this.oldParentId != this.parentId })
      }
      this.sureBtnLoading = false
    },
    bindEvent () {
      const _this = this
      this.$nextTick(() => {
        let cascaderDom = document.getElementsByClassName('el-cascader__dropdown')[0]
        if (cascaderDom) {
          setTimeout(() => {
            let labelDoms = cascaderDom.querySelectorAll('.el-cascader-node .el-cascader-node__label')
            let radioDoms = cascaderDom.querySelectorAll('.el-cascader-node .el-radio')
            if (labelDoms && labelDoms.length) {
              labelDoms.forEach((item, index) => {
                item.addEventListener('click', () => {
                  radioDoms[index].click()
                })
              })
              labelDoms.forEach((item) => {
                item.addEventListener('dblclick', () => {
                  _this.$refs.elCascader.dropDownVisible = false
                })
              })
            }
          }, 500)
        }
      })
    },
  },
  created () {
    if (this.type === 0) {
      this.form.folder_name = this.name
      this.form.order = this.order
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.tips {
		span {
			color: red;
		}
	}
}
</style>