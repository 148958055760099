import Vue from 'vue'
// 导入组件
import PreviewFileDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个 "子类"
const PreviewFileConstructor = Vue.extend(PreviewFileDialog)

let previewFileInstance = null
/**
 * 初始化下载文件进度条
 * @param {object | array} fileInfo 文件信息
 */
const initInstanceFileDetail = (fileInfo) => {
  previewFileInstance = new PreviewFileConstructor({
    el: document.createElement('div'),
    data () {
      return {
        fileInfo
      }
    }
  })
}
/**
 * 文件详情文件 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
const previewFileDialog = (obj) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (previewFileInstance !== null) {
    document.body.removeChild(previewFileInstance.$el)
  }
  let { fileInfo } = obj
  return new Promise((reslove) => {
    initInstanceFileDetail(fileInfo)
    previewFileInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res === 'cancel' && previewFileInstance !== null) {
        document.body.removeChild(previewFileInstance.$el)
        previewFileInstance = null
      }
    }
    document.body.appendChild(previewFileInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      previewFileInstance.visible = true //  打开对话框
    })
  })
}

export default previewFileDialog
