<template>
	<!-- <el-drawer :destroy-on-close="true" :title="title" :visible.sync="visible" :direction="'rtl'"
    :before-close="handleClose" :size="'100%'" ref="drawer">

  </el-drawer> -->
	<el-dialog
		:title="title"
		center
		:modal="false"
		:lock-scroll="false"
		:close-on-press-escape="false"
		:show-close="false"
		:destroy-on-close="true"
		:visible.sync="visible"
		fullscreen
		:top="'5vh'"
	>
		<div v-if="type === 1 || type === 3" class="content">
			<div class="left">
				<p>选择人员：</p>
				<br />
				<el-input placeholder="输入人名进行搜索" v-model="u_filterText">
				</el-input>
				<p>注意1：此数据并不会及时更新，离职及入职人员更新会有24小时延迟！</p>
				<p>注意2：不同部门里的同一个用户不需要重复勾选，重复勾选无效！</p>
				<br />
				<el-tree
					:data="u_data"
					ref="u_tree"
					:props="u_defaultProps"
					show-checkbox
					v-loading="u_loading"
					@check="u_handleCheckChange"
					node-key="id"
					:default-expanded-keys="u_dExpandedKeys"
					:default-checked-keys="u_dCheckedKeys"
					:filter-node-method="u_filterNode"
				>
					<span class="custom-tree-node" slot-scope="{ data }">
						<span style="margin-right: 15px">{{ data.name }}</span>
						<el-tag
							style="margin-right: 15px"
							v-if="data.position"
							size="mini"
							>{{ data.position }}</el-tag
						>
						<el-tag v-if="data.leader" size="mini">主管</el-tag>
					</span>
				</el-tree>
			</div>
			<div class="right">
				<p>已选人员：</p>
				<br />
				<TablePage>
					<template #table>
						<el-table
							border
							:data="u_tableData"
							style="width: 100%"
							height="100%"
						>
							<el-table-column prop="name" label="姓名" width="300">
							</el-table-column>
							<el-table-column prop="department" label="部门">
							</el-table-column>
						</el-table>
					</template>
				</TablePage>
			</div>
		</div>
		<div v-else class="content">
			<div class="left">
				<p>选择文件夹：</p>
				<br />
				<el-tree
					:data="f_data"
					ref="f_tree"
					:props="f_props"
					node-key="id"
					show-checkbox
					@check="f_handleCheckChange"
					:default-checked-keys="f_dCheckedKeys"
					v-loading="f_loading"
				>
					<template #default="{ data, node }">
						<span>
							<i
								style="color: #ffcc66"
								:class="
									node.expanded
										? 'iconfont icon-24gf-folderOpen'
										: 'iconfont icon-24gf-folder'
								"
							></i>
							{{ data.title }}
						</span>
					</template>
				</el-tree>
			</div>
			<div class="right">
				<p>已全选文件夹权限设置：</p>
				<br />
				<el-form ref="form" label-width="120px">
					<el-form-item label="上传">
						<el-switch
							@change="isChangeTree = true"
							v-model="upload"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-value="1"
							:inactive-value="0"
							v-if="!f_loading"
						>
						</el-switch>
					</el-form-item>
					<el-form-item label="预览">
						<el-switch
							@change="isChangeTree = true"
							v-model="preview"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-value="1"
							:inactive-value="0"
							v-if="!f_loading"
						>
						</el-switch>
					</el-form-item>
					<el-form-item label="删除">
						<el-switch
							@change="isChangeTree = true"
							v-model="deleted"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-value="1"
							:inactive-value="0"
							v-if="!f_loading"
						>
						</el-switch>
					</el-form-item>
					<el-form-item label="下载">
						<el-switch
							@change="isChangeTree = true"
							v-model="download"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-value="1"
							:inactive-value="0"
							v-if="!f_loading"
						>
						</el-switch>
					</el-form-item>
					<el-form-item label="新建文件夹">
						<el-switch
							@change="isChangeTree = true"
							v-model="folder"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-value="1"
							:inactive-value="0"
							v-if="!f_loading"
						>
						</el-switch>
					</el-form-item>
					<el-form-item label="上传文件夹">
						<el-switch
							@change="isChangeTree = true"
							v-model="upload_folder"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-value="1"
							:inactive-value="0"
							v-if="!f_loading"
						>
						</el-switch>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium"
				>取消，不做任何修改</el-button
			>
			<el-button
				type="primary"
				size="medium"
				v-loading="btnLoad"
				@click="saveBtn"
				>保 存 修 改</el-button
			>
		</span>
	</el-dialog>
</template>

<script>
import { getUserFolder, getUserList, saveUserGroup, saveUserGroupPermission, getFolderByAdmin, saveFolderAdmin } from '_s/fileStorage.js';
import TablePage from '_c/tablePage';
export default {
  components: {
    TablePage
  },
  data () {
    return {
      visible: false,
      f_data: [],
      f_props: {
        label: 'title',
        children: 'children'
      },
      f_loading: false,
      u_data: [],
      u_defaultProps: {
        children: 'children',
        label: 'name'
      },
      u_loading: false,
      u_tableData: [],
      u_filterText: '',
      u_dCheckedKeys: [],
      u_dExpandedKeys: ['p1'],
      f_dCheckedKeys: [],
      // f_dExpandedKeys: [],
      isChangeTree: false,
      btnLoad: false,
      upload: 1,
      preview: 1,
      deleted: 1,
      download: 1,
      folder: 1,
      upload_folder: 1
    }
  },
  watch: {
    u_filterText (val) {
      this.$refs.u_tree.filter(val);
    }
  },
  methods: {
    u_filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    async saveBtn () {
      this.btnLoad = true
      if (!this.isChangeTree) return this.visible = false
      if (this.type === 1) {
        //人员绑定用户组
        const res = new Map();
        const uids = this.u_tableData.filter((a) => (!res.has(a.id) && res.set(a.id, 1)))
        const { code } = await saveUserGroup({ groupId: this.groupId, uids })
        if (code === 0) {
          this.visible = false
        } else {
          this.btnLoad = false
        }
      } else if (this.type === 3) {
        //人员绑定文件夹
        const res = new Map();
        const uids = this.u_tableData.filter((a) => (!res.has(a.id) && res.set(a.id, 1)))
        const { code } = await saveFolderAdmin({ folderId: this.groupId, uids })
        if (code === 0) {
          this.visible = false
        } else {
          this.btnLoad = false
        }
      } {
        // 文件夹绑定用户组
        const real = this.$refs.f_tree.getCheckedKeys() // 选中节点的key数组
        const half = this.$refs.f_tree.getHalfCheckedNodes() //半选的节点
        const ckeckNodes = this.$refs.f_tree.getCheckedNodes() //全选的节点
        half.forEach(node => {
          node.upload = 0
          node.preview = 0
          node.delete = 0
          node.download = 0
          node.folder = 0
          node.upload_folder = 0
        })
        ckeckNodes.forEach(node => {
          node.upload = this.upload
          node.preview = this.preview
          node.delete = this.deleted
          node.download = this.download
          node.folder = this.folder
          node.upload_folder = this.upload_folder
        })
        const permission = half.concat(ckeckNodes)
        const { code } = await saveUserGroupPermission({ groupId: this.groupId, permission, real })
        if (code === 0) {
          this.visible = false
        } else {
          this.btnLoad = false
          this.f_showFolderList()
        }
      }
    },
    async f_showFolderList () {
      this.f_loading = true
      const { data } = await getUserFolder({ groupId: this.groupId })
      this.f_dCheckedKeys = data.real
      this.f_data = Object.freeze(data.list)
      this.upload = data.upload
      this.preview = data.preview
      this.deleted = data.delete
      this.download = data.download
      this.folder = data.folder
      this.upload_folder = data.upload_folder
      this.f_loading = false
    },
    async u_showUserList () {
      this.u_loading = true
      const { data } = this.type === 1 ? await getUserList({ groupId: this.groupId }) : await getFolderByAdmin({ folderId: this.groupId })
      this.u_data = data.list
      const playLoad = data.playLoad.map(i => i.id.toString())
      this.u_dCheckedKeys = playLoad
      this.u_dExpandedKeys = [...this.u_dExpandedKeys, ...playLoad]
      this.u_tableData = data.playLoad
      this.u_loading = false
    },
    f_handleCheckChange () {
      this.isChangeTree = true
    },
    u_handleCheckChange (node, nodeData) {
      this.isChangeTree = true
      this.u_tableData = nodeData.checkedNodes.map(i => {
        if (!i.id.startsWith('p')) {
          return { id: i.id, name: i.name, leaderUid: i.leaderUid, leaderName: i.leaderName, department: i.department }
        }
      }).filter(Boolean)
    }
  },
  created () {
    if (this.type === 1 || this.type === 3) {
      this.u_showUserList()
    } else {
      this.f_showFolderList()
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.el-dialog__body) {
	padding: 0;
	height: 85vh;
}

:deep(.el-dialog__footer) {
	padding: 20px;
}

.content {
	display: flex;
	height: 100%;

	.left {
		word-break: break-all;
		padding: 20px;
		width: 50%;
		border-right: 1px solid #999999;
		border-top: 1px solid #999999;
		border-bottom: 1px solid #999999;
		text-overflow: ellipsis;
		overflow: auto;
	}

	.right {
		word-break: break-all;
		padding: 20px;
		width: 50%;
		border-top: 1px solid #999999;
		border-bottom: 1px solid #999999;
		display: flex;
		flex-direction: column;
	}
}
</style>