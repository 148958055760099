<template>
	<!-- 新建文件夹对话框 -->
	<el-dialog
		title="新建下载任务"
		:visible.sync="visible"
		:close-on-click-modal="false"
		width="580px"
		@close="handleDialogClose"
		center
		:destroy-on-close="true"
	>
		<el-form
			class="add-folder-form"
			:model="form"
			:rules="formRules"
			ref="form"
			label-width="100px"
			label-position="top"
		>
			<el-form-item label="打包下载文件夹" prop="fid">
				<el-cascader
					:props="props"
					v-model="form.fid"
					style="width: 100%"
					:show-all-levels="false"
					@visible-change="bindEvent"
					@expand-change="bindEvent"
					ref="elCascader"
					class="elCascader"
				></el-cascader>
			</el-form-item>
			<el-form-item label="打包下载用途" prop="remark">
				<el-input
					v-model="form.remark"
					placeholder="请输入打包下载用途"
					type="textarea"
					:autosize="{ minRows: 3, maxRows: 3 }"
					maxlength="255"
					show-word-limit
					@keydown.enter.native.prevent
				></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleDialogClose">取 消</el-button>
			<el-button
				type="primary"
				:loading="sureBtnLoading"
				@click="handleDialogSure()"
				>确 定</el-button
			>
		</div>
	</el-dialog>
</template>

<script>
import { getFolderList, newDownloadTask } from '_s/fileStorage.js';
export default {
  name: 'AddFolderDialog',
  data () {
    return {
      visible: false, //  对话框是否可见
      form: {
        remark: '',
        fid: 0,
      },
      formRules: {
        remark: { required: true, message: '请输入打包下载用途', trigger: 'blur' },
        fid: { required: true, message: '请选择打包下载的文件夹', trigger: 'change' }
      },
      sureBtnLoading: false,
      props: {
        emitPath: false,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          getFolderList({ folderId: node.value }).then(res => {
            const node = res.data.map(item => ({ value: item.id, label: item.title, leaf: false }))
            resolve(node)
          })
        }
      }
    }
  },
  methods: {
    /**
     * 取消按钮点击事件 & 对话框关闭的回调
     * @description 关闭对话框，重置表单
     */
    handleDialogClose () {
      this.$refs['form'].resetFields()
      this.visible = false
      this.callback({ done: false })
    },
    /**
     * 确定按钮点击事件
     * @description 校验表单，校验通过后调用新建文件夹接口
     */
    async handleDialogSure () {
      this.sureBtnLoading = true
      try {
        await this.$refs['form'].validate()
        const res = await newDownloadTask(this.form)
        if (res.code == 0) {
          this.visible = false
          this.callback({ done: true })
        }
      } catch (error) {
        console.log(error)
      }
      this.sureBtnLoading = false
    },
    bindEvent () {
      const _this = this
      this.$nextTick(() => {
        let cascaderDom = document.getElementsByClassName('el-cascader__dropdown')[0]
        if (cascaderDom) {
          setTimeout(() => {
            let labelDoms = cascaderDom.querySelectorAll('.el-cascader-node .el-cascader-node__label')
            let radioDoms = cascaderDom.querySelectorAll('.el-cascader-node .el-radio')
            if (labelDoms && labelDoms.length) {
              labelDoms.forEach((item, index) => {
                item.addEventListener('click', () => {
                  radioDoms[index].click()
                })
              })
              labelDoms.forEach((item) => {
                item.addEventListener('dblclick', () => {
                  _this.$refs.elCascader.dropDownVisible = false
                })
              })
            }
          }, 500)
        }
      })
    },
  },
  created () {
  }
}
</script>
<style lang="scss" scoped>
</style>