export default {
  path: '/ctms',
  component: () => import(/* webpackChunkName: 'ctms' */'@/views/project/ctms/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '/ctms/project',
      name: 'projectView',
      component: () => import(/* webpackChunkName: 'projectView' */'@/views/project/ctms/p_project/index')
    },
    {
      path: '/ctms/project/monitoring',
      name: 'proMmonitoring',
      component: () => import(/* webpackChunkName: 'proMmonitoring' */'@/views/project/ctms/p_project/monitoring')
    },
    {
      path: '/ctms/project/communicate',
      name: 'proMcommunicate',
      component: () => import(/* webpackChunkName: 'proMcommunicate' */'@/views/project/ctms/p_project/communicate')
    },
    {
      path: '/ctms/project/pd',
      name: 'proMpd',
      component: () => import(/* webpackChunkName: 'proMpd' */'@/views/project/ctms/p_project/pd')
    },
    {
      path: '/ctms/project/oracleMilestone',
      name: 'oracleMilestone',
      component: () => import(/* webpackChunkName: 'oracleMilestone' */'@/views/project/ctms/p_project/oracleMilestone')
    },
    {
      path: '/ctms/project/contractMilestones',
      name: 'contractMilestones',
      component: () => import(/* webpackChunkName: 'contractMilestones' */'@/views/project/ctms/p_project/contractMilestones')
    },
    {
      path: '/ctms/project/payPlanMilestones',
      name: 'payPlanMilestones',
      component: () => import(/* webpackChunkName: 'payPlanMilestones' */'@/views/project/ctms/p_project/payPlanMilestones')
    },
    {
      path: '/ctms/project/monitoringIssues',
      name: 'monitoringIssues',
      component: () => import(/* webpackChunkName: 'monitoringIssues' */'@/views/project/ctms/p_project/monitoringIssues')
    },
    {
      path: '/ctms/project/monitoringIssuesVaccine',
      name: 'monitoringIssuesVaccine',
      component: () => import(/* webpackChunkName: 'monitoringIssuesVaccine' */'@/views/project/ctms/p_project/monitoringIssuesVaccine')
    },
    {
      path: '/ctms/project/sae',
      name: 'proMsae',
      component: () => import(/* webpackChunkName: 'proMsae' */'@/views/project/ctms/p_project/sae')
    },
    {
      path: '/ctms/center',
      name: 'center',
      component: () => import(/* webpackChunkName: 'center' */'@/views/project/ctms/p_center/view')
    },
    {
      path: '/ctms/center/milestone',
      name: 'centerMilestone',
      component: () => import(/* webpackChunkName: 'centerMilestone' */'@/views/project/ctms/p_center/milestone')
    },
    {
      path: '/ctms/center/patient',
      name: 'centerPatient',
      component: () => import(/* webpackChunkName: 'centerPatient' */'@/views/project/ctms/p_center/patient')
    },
    {
      path: '/ctms/center/sae',
      name: 'centerPatient',
      component: () => import(/* webpackChunkName: 'centerPatient' */'@/views/project/ctms/p_center/sae')
    },
    {
      path: '/ctms/center/icf',
      name: 'icf',
      component: () => import(/* webpackChunkName: 'icf' */'@/views/project/ctms/p_center/icf')
    },
    {
      path: '/ctms/center/monitoringIssues',
      name: 'monitoringIssues',
      component: () => import(/* webpackChunkName: 'monitoringIssues' */'@/views/project/ctms/p_center/monitoringIssues')
    },
    {
      path: '/ctms/trails',
      name: 'ctmstrails',
      component: () => import(/* webpackChunkName: 'ctmstrails' */'@/views/project/ctms/p_trails/index')
    },
    {
      path: '/ctms/project/milestone',
      name: 'ctmsMilestone',
      component: () => import(/* webpackChunkName: 'ctmsMilestone' */'@/views/project/ctms/p_project/milestone2')
    },
    {
      path: '/ctms/project/quarterSchedule',
      name: 'ctmsQuarterSchedule',
      component: () => import(/* webpackChunkName: 'ctmsMilestone' */'@/views/project/ctms/p_project/quarterSchedule')
    },
    {
      path: '/ctms/project/milestonePlan',
      name: 'ctmsMilestonePlan',
      component: () => import(/* webpackChunkName: 'ctmsMilestone' */'@/views/project/ctms/p_project/milestonePlan')
    },
    {
      path: '/ctms/project/contacts',
      name: 'projectcontacts',
      component: () => import(/* webpackChunkName: 'projectcontacts' */'@/views/project/ctms/p_project/contacts')
    },
    {
      path: '/ctms/center/contacts',
      name: 'centercontacts',
      component: () => import(/* webpackChunkName: 'centercontacts' */'@/views/project/ctms/p_center/contacts')
    },
    {
      path: '/ctms/set',
      name: 'ctmsset',
      component: () => import(/* webpackChunkName: 'ctmsset' */'@/views/project/ctms/p_set/center')
    },
    {
      path: '/ctms/set/visit',
      name: 'visit',
      component: () => import(/* webpackChunkName: 'ctmsset' */'@/views/project/ctms/p_set/visit')
    },
    {
      path: '/ctms/set/member',
      name: 'ctmsMember',
      component: () => import('@/views/project/ctms/p_set/member')
    },
    {
      path: '/ctms/set/milestone',
      name: 'ctmsMilestone',
      component: () => import(/* webpackChunkName: 'ctmsMilestone' */'@/views/project/ctms/p_set/milestone')
    },
    {
      path: '/ctms/set/workflow',
      name: 'ctmsWorkflow',
      component: () => import(/* webpackChunkName: 'ctmsWorkflow' */'@/views/project/ctms/p_set/workflow')
    },
    {
      path: '/ctms/center/pd',
      name: 'centerpd',
      component: () => import(/* webpackChunkName: 'centerpd' */'@/views/project/ctms/p_center/pd')
    },
    {
      path: '/ctms/center/monitoringPlan',
      name: 'monitoringPlan',
      component: () => import(/* webpackChunkName: 'monitoringPlan' */'@/views/project/ctms/p_center/monitoringPlan')
    },
    {
      path: '/ctms/center/monitoring',
      name: 'monitoring',
      component: () => import(/* webpackChunkName: 'monitoring' */'@/views/project/ctms/p_center/monitoring')
    }
    ,
    {
      path: '/ctms/center/communicate',
      name: 'communicate',
      component: () => import(/* webpackChunkName: 'communicate' */'@/views/project/ctms/p_center/communicate')
    }
    ,
    {
      path: '/ctms/project/monitoringReport',
      name: 'monitoringReport',
      component: () => import(/* webpackChunkName: 'monitoringReport' */'@/views/project/ctms/p_project/monitoringReport')
    },
    {
      path: '/ctms/project/workHours',
      name: 'workHours',
      component: () => import(/* webpackChunkName: 'workHours' */'@/views/project/ctms/p_project/workHours')
    },
    {
      path: '/ctms/project/workHoursSet',
      name: 'workHoursSet',
      component: () => import(/* webpackChunkName: 'phase' */'@/views/project/ctms/p_project/workHoursSet')
    }
  ]
}