<template>
	<div>
		<el-dialog :visible.sync="visible" fullscreen class="viewDialog">
			<div slot="title" class="viewTitle">
				<div class="viewHeader">
					<span class="viewSpan">监查计划</span>
					<el-divider direction="vertical" style="heigth: 100%"></el-divider>
					<div>
						<p>{{ viewObject.project_name }}</p>
						<p class="viewHospital">
							{{ viewObject.hospital_number }}&nbsp;{{
								viewObject.hospital_name
							}}
						</p>
					</div>
				</div>
			</div>
			<el-tabs v-model="form.type">
				<el-tab-pane name="index1" label="监查计划">
					<div class="tabTitle">
						{{ viewObject.type_name }}：{{ viewObject.plan_number }}
					</div>
					<div class="tabContent">
						<el-form
							title="监查计划"
							:column="2"
							label-position="right"
							label-width="150px"
						>
							<el-form-item label="监查类型：">{{
								viewObject.type_name
							}}</el-form-item>
							<el-form-item label="计划编号：">{{
								viewObject.plan_number
							}}</el-form-item>
							<el-form-item label="中心编号：">{{
								viewObject.hospital_number
							}}</el-form-item>
							<el-form-item label="中心名称：">{{
								viewObject.hospital_name
							}}</el-form-item>
							<el-form-item label="计划开始日期：">{{
								viewObject.plan_start_date
							}}</el-form-item>
							<el-form-item label="计划结束时间：">{{
								viewObject.plan_end_date
							}}</el-form-item>
							<el-form-item label="计划监查时长(h)：">{{
								viewObject.plan_hours
							}}</el-form-item>
							<el-form-item label="当前监查次数：">{{
								viewObject.plan_current_times
							}}</el-form-item>
							<el-form-item label="上次监查开始日期：">{{
								viewObject.last_time_start_date
							}}</el-form-item>
							<el-form-item label="上次监查结束日期：">{{
								viewObject.last_time_end_date
							}}</el-form-item>
							<el-form-item label="计划监查员：">{{
								viewObject.plan_cra_name
							}}</el-form-item>
							<el-form-item label="中心参与人员：">{{
								viewObject.hospital_participants_value
							}}</el-form-item>
							<el-form-item label="申办方：">{{
								viewObject.sponsor
							}}</el-form-item>
							<el-form-item label="申办方人员：">{{
								viewObject.sponsor_participants_value
							}}</el-form-item>
							<el-form-item label="计划监查内容：">
								<ul class="viewUL">
									<li
										v-for="(
											item, index
										) in viewObject.plan_monitoring_content_value"
										:key="index"
									>
										{{ item }}
									</li>
								</ul>
							</el-form-item>
							<el-form-item label="	其他监查内容：">{{
								viewObject.other_monitoring_content
							}}</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane name="Confirmation" label="确认信" v-if="type">
					<viewForm
						:type_name="viewObject.type_name"
						:plan_number="viewObject.plan_number"
						:row="row"
						@download="download"
						template_type="Confirmation"
						@sendEmail="sendEmail"
						@sendEmailList="sendEmailList"
						:showSend="true"
						:view="view"
						:btn="true"
					></viewForm>
				</el-tab-pane>
				<el-tab-pane label="监查报告" :disabled="viewObject.status != 2">
					<viewForm
						:type_name="viewObject.type_name"
						:plan_number="viewObject.plan_number"
						:row="row"
						@download="download"
						template_type="MonitoringReport"
						:showSend="false"
						:view="view"
						:btn="type"
						@handleViewPDFVisible="handleViewPDFVisible"
					></viewForm>
				</el-tab-pane>
				<el-tab-pane
					name="FollowUp"
					:label="viewObject.type === 1 ? '答谢信' : '随访信'"
					v-if="type && tabs"
					:disabled="viewObject.status != 2"
				>
					<viewForm
						:type_name="viewObject.type_name"
						:plan_number="viewObject.plan_number"
						:row="row"
						@download="download"
						template_type="FollowUp"
						@sendEmail="sendEmail"
						@sendEmailList="sendEmailList"
						:showSend="true"
						:view="view"
						:btn="true"
					></viewForm>
				</el-tab-pane>
				<el-tab-pane label="执行信息" v-if="type">
					<div
						class="tabTitle"
						style="
							display: flex;
							align-items: center;
							justify-content: space-between;
						"
					>
						<span>
							{{ viewObject.type_name }}：{{ viewObject.plan_number }}
						</span>
						<el-button-group>
							<el-button type="primary" @click="zhixing = false" size="mini"
								>编辑</el-button
							>
							<el-button
								type="primary"
								v-if="!zhixing"
								@click="zhixingSave"
								size="mini"
								>保存</el-button
							>
						</el-button-group>
					</div>
					<div style="padding: 10px">
						<el-form
							:model="zhixingForm"
							:rules="zhixingRules"
							ref="zhixingForm"
							label-width="200px"
							size="mini"
						>
							<el-row>
								<el-col :span="12">
									<el-form-item label="实际开始时间：" prop="start_date">
										<span v-if="zhixing">{{ zhixingForm.start_date }}</span>
										<el-date-picker
											v-else
											class="fullwidth"
											v-model="zhixingForm.start_date"
											type="datetime"
											placeholder="选择实际开始时间"
											value-format="yyyy-MM-dd HH:mm"
										>
										</el-date-picker> </el-form-item
								></el-col>
								<el-col :span="12">
									<el-form-item label="实际结束时间：" prop="end_date">
										<span v-if="zhixing">{{ zhixingForm.end_date }}</span>
										<el-date-picker
											v-else
											class="fullwidth"
											v-model="zhixingForm.end_date"
											type="datetime"
											placeholder="选择实际结束时间"
											value-format="yyyy-MM-dd HH:mm"
										>
										</el-date-picker> </el-form-item></el-col
							></el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="实际监查时长(h)：" prop="real_hours">
										<span v-if="zhixing">{{ zhixingForm.real_hours }}</span>
										<el-input
											v-else
											v-model="zhixingForm.real_hours"
											placeholder="请输入实际监查时长"
										></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="实际监查员：" prop="real_cra">
										<div style="display: flex; align-items: center">
											<!-- <el-button
												v-if="!zhixing"
												icon="el-icon-search"
												circle
												size="mini"
												type="primary"
												@click="selectUser()"
											></el-button>
											<span style="flex: 1; height: 28px; margin: 5px">
												{{ zhixingForm.real_cra_label }}
											</span> -->
											<el-select
												v-model="zhixingForm.real_cra"
												multiple
												placeholder="请选择实际监查员"
												class="fullwidth"
												v-if="!zhixing"
											>
												<el-option
													v-for="item in members"
													:key="item.uid"
													:label="item.user_name"
													:value="item.uid"
												>
												</el-option>
											</el-select>
											<span style="flex: 1; height: 28px" v-if="zhixing">
												{{ zhixingForm.real_cra_label }}
											</span>
										</div>
									</el-form-item></el-col
								></el-row
							>
							<el-row>
								<el-col :span="12">
									<el-form-item
										label="申办方人员："
										prop="real_sponsor_participants"
									>
										<span style="flex: 1; height: 28px" v-if="zhixing">{{
											zhixingForm.real_sponsor_participants_label
										}}</span>
										<el-select
											v-else
											v-model="zhixingForm.real_sponsor_participants"
											multiple
											placeholder="请选择申办方人员"
											class="fullwidth"
										>
											<el-option
												v-for="item in sponsor_participantsList"
												:key="item.id"
												:label="item.name"
												:value="item.id"
											>
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item
										label="中心参与人员："
										prop="hospital_participants"
									>
										<span
											v-if="zhixing"
											style="flex: 1; height: 28px; margin-left: 5px"
											>{{ zhixingForm.real_hospital_participants_label }}</span
										>
										<el-select
											v-else
											v-model="zhixingForm.real_hospital_participants"
											multiple
											placeholder="请选择中心参与人员"
											class="fullwidth"
										>
											<el-option
												v-for="item in hospital_participantsList"
												:key="item.id"
												:label="item.name"
												:value="item.id"
											>
											</el-option>
										</el-select>
									</el-form-item> </el-col
							></el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="协同监查人员：">
										<div style="display: flex; align-items: center">
											<el-button
												v-if="!zhixing"
												icon="el-icon-search"
												circle
												size="mini"
												type="primary"
												@click="selectUser2()"
											></el-button>
											<span style="flex: 1; height: 28px; margin-left: 5px">
												{{ zhixingForm.real_collaborative_personnel_label }}
											</span>
										</div>
									</el-form-item>
								</el-col>
								<el-col :span="12"></el-col>
							</el-row>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane
					:label="`${
						approvalType === 'MonitoringPlan' ? '监查计划' : '监查报告'
					}审批情况`"
				>
					<div class="tabTitle">
						{{ viewObject.type_name }}：{{ viewObject.plan_number }}
					</div>
					<el-empty
						description="暂无数据"
						v-if="resultList.length < 1"
					></el-empty>
					<el-card
						class="box-card"
						v-for="card in resultList"
						:key="card.title"
					>
						<h4>{{ card.title }}</h4>
						<p>{{ card.userName }}&nbsp;{{ card.sumbitTime | dateFormat }}</p>
						<br />
						<el-table :data="card.table" border style="width: 100%">
							<el-table-column prop="node" label="审批节点"> </el-table-column>
							<el-table-column prop="user_name" label="处理人">
							</el-table-column>
							<el-table-column label="操作时间">
								<template slot-scope="scope">
									{{ scope.row.createdAt | dateFormat }}
								</template>
							</el-table-column>
							<el-table-column prop="status" label="审批结果">
								<template slot-scope="scope">
									<p class="markerLine" v-if="scope.row.status === 0">
										<span class="marker"> </span>
										未处理
									</p>
									<p class="markerLine" v-if="scope.row.status === 2">
										<span class="marker-green"> </span>
										通过
									</p>
									<p class="markerLine" v-if="scope.row.status === 3">
										<span class="marker-red"> </span>
										驳回
									</p>
								</template>
							</el-table-column>
							<el-table-column prop="remarks" label="意见"> </el-table-column>
						</el-table>
					</el-card>
				</el-tab-pane>
				<el-tab-pane label="审批" v-if="!type && row.status == 1">
					<el-form :model="approval" :rules="approvalForm" ref="approvalForm">
						<el-form-item label="任务：" label-width="120px">
							{{ approval.title }}
						</el-form-item>
						<el-form-item
							prop="approval_status"
							label="提交审批："
							label-width="120px"
						>
							<el-select
								v-model="approval.approval_status"
								placeholder="请选择审批结果"
								style="width: 100%"
							>
								<el-option label="通过" value="2"></el-option>
								<el-option label="驳回" value="3"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="审批意见：" label-width="120px">
							<el-input
								v-model="approval.remark"
								autocomplete="off"
								placeholder="请输入审批意见"
							></el-input>
						</el-form-item>
						<el-form-item class="shenpi">
							<el-button type="primary" @click="sumbitApproval"
								>提交审批</el-button
							>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<el-dialog
			title="正在下载，请等待"
			:visible.sync="downloadVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			width="50%"
		>
			<div style="text-align: center">
				<el-progress type="circle" :percentage="percentage"></el-progress>
			</div>
			<br />
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="downClose">取消下载</el-button>
			</div>
		</el-dialog>
		<el-drawer title="发送" :visible.sync="drawer" size="70%">
			<div class="send">
				<el-form
					ref="form"
					:model="form"
					label-width="80px"
					size="mini"
					:rules="rules"
				>
					<el-form-item label="寄件地址">
						<div>
							<span>{{ form.mail_from }}</span>
							<el-link
								@click="emailSetHandle"
								v-if="form.emailSet"
								type="danger"
								style="margin-left: 15px"
								>未配置，点击去配置</el-link
							>
						</div>
					</el-form-item>
					<el-form-item label="收件地址" prop="mail_to">
						<!-- <el-input
							v-model="form.mail_to"
							placeholder="如有多个邮箱请以 ; 分割"
						></el-input> -->
						<el-select
							filterable
							allow-create
							default-first-option
							v-model="form.mail_to"
							placeholder="请选择收件地址"
							multiple
							class="fullwidth"
						>
							<el-option-group
								v-for="group in emailList"
								:key="group.title"
								:label="group.title"
							>
								<el-option
									v-for="item in group.children"
									:key="item.email"
									:label="item.email"
									:value="item.email"
								>
									<span style="float: left">{{ item.email }}</span>
									<span style="float: right; color: #8492a6; font-size: 13px">{{
										item.name
									}}</span>
								</el-option>
							</el-option-group>
						</el-select>
					</el-form-item>
					<el-form-item label="抄送">
						<!-- <el-input
							v-model="form.mail_cc"
							placeholder="如有多个邮箱请以 ; 分割"
						></el-input> -->
						<el-select
							filterable
							allow-create
							default-first-option
							v-model="form.mail_cc"
							placeholder="请选择抄送地址"
							multiple
							class="fullwidth"
						>
							<el-option-group
								v-for="group in emailList"
								:key="group.title"
								:label="group.title"
							>
								<el-option
									v-for="item in group.children"
									:key="item.email"
									:label="item.email"
									:value="item.email"
								>
									<span style="float: left">{{ item.email }}</span>
									<span style="float: right; color: #8492a6; font-size: 13px">{{
										item.name
									}}</span>
								</el-option>
							</el-option-group>
						</el-select>
					</el-form-item>
					<el-form-item label="主题" prop="mail_title">
						<el-input v-model="form.mail_title"></el-input>
					</el-form-item>
					<el-form-item label="正文内容" prop="mail_content">
						<div style="margin-top: 10px; border: 1px solid #ccc">
							<!-- 工具栏 -->
							<Toolbar
								style="border-bottom: 1px solid #ccc"
								:editor="editor"
								:mode="`simple`"
								:defaultConfig="toolbarConfig"
							/>
							<!-- 编辑器 -->
							<Editor
								style="height: 400px; overflow-y: hidden"
								v-model="form.mail_content"
								@onCreated="onCreated"
								:defaultConfig="editorConfig"
							/>
						</div>
					</el-form-item>
					<el-form-item label="签名内容">
						<div style="margin-top: 10px; border: 1px solid #ccc">
							<!-- 工具栏 -->
							<Toolbar
								style="border-bottom: 1px solid #ccc"
								:editor="autographEditor"
								:mode="`simple`"
								:defaultConfig="toolbarConfig"
							/>
							<!-- 编辑器 -->
							<Editor
								style="height: 400px; overflow-y: hidden"
								v-model="form.sign_content"
								@onCreated="onCreatedAutograph"
							/>
						</div>
					</el-form-item>
					<el-form-item label="附件"> {{ form.pdf_name }} </el-form-item>
					<el-form-item>
						<div style="padding: 10px">
							<el-button type="primary" @click="onSendMailSave"
								>立即发送</el-button
							>
							<el-button @click="closeDrawer">取消</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
		<el-drawer title="发送记录" :visible.sync="emailListDrawer" size="70%">
			<div class="send">
				<TablePage>
					<template #table>
						<el-table :data="emailLogs" height="100%">
							<el-table-column prop="status" label="状态">
								<template slot-scope="scope">
									<span v-if="scope.row.status == 0">正在发送</span>
									<span v-if="scope.row.status == 1">成功</span>
									<span v-if="scope.row.status == 2">失败</span>
								</template>
							</el-table-column>
							<el-table-column prop="mail_from" label="发件人">
							</el-table-column>
							<el-table-column label="收件地址" prop="mail_to">
							</el-table-column>
							<el-table-column label="抄送" prop="mail_cc"> </el-table-column>
							<el-table-column prop="mail_title" label="邮件主题">
							</el-table-column>
							<el-table-column label="内容">
								<template slot-scope="scope">
									<el-link type="primary" @click="viewEmail(scope.row)"
										>查看详情</el-link
									>
								</template>
							</el-table-column>
							<el-table-column prop="pdf_name" label="附件"> </el-table-column>
						</el-table>
					</template>
					<template #footer>
						<div class="footer">
							<!-- 分页组件 -->
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page="emailLogsForm.page"
								:page-sizes="[20, 50, 100, 300]"
								:page-size="emailLogsForm.pageSize"
								layout="total, sizes, prev, pager, next, jumper"
								:total="emailListTotal"
							>
							</el-pagination>
						</div>
					</template>
				</TablePage>
			</div>
		</el-drawer>
		<el-dialog :visible.sync="viewPDFVisible" :fullscreen="true">
			<iframe
				ref="iframe"
				:src="src"
				frameborder="0"
				style="width: 100%; height: 100%"
			></iframe>
		</el-dialog>
	</div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import viewForm from './components/viewForm.vue';
import axios from 'axios'
import store from '@/store'
import {
  hospitalplandetail,
  hospitalplanapprovalDetail,
  projectHospitalContactsList,
  projectContactsList,
  executeInfoSave,
  projectconfigmembersList,
  reportDownload,
  sendMailSave,
  sendMailList,
  hospitalplanReportDetail,
  emailContactsList,
  projectplanapproval,
  planReportApproval
} from '_s/project'
import localStorage from '_l/localStorage'
import router from '@/router'
import TablePage from '_c/tablePage'
export default {
  components: {
    viewForm,
    Editor,
    Toolbar,
    TablePage,
  },
  data () {
    return {
      members: '',
      viewObject: {},
      visible: false,
      resultList: [],
      percentage: 0,
      downloadVisible: false,
      zhixing: true,
      zhixingForm: {
        start_date: '',
        end_date: '',
        real_hours: '',
        real_cra: '',
        real_collaborative_personnel: '',
        real_collaborative_personnel_label: '',
        real_hospital_participants: '',
        real_sponsor_participants: '',
        real_cra_label: ''
      },
      zhixingRules: {
        start_date: { required: true, message: '请选择实际开始日期', trigger: 'change' },
        end_date: { required: true, message: '请选择实际结束时间', trigger: 'change' },
        real_hours: { required: true, message: '请选择实际监查时长', trigger: 'change' },
        real_cra: { required: true, message: '请选择实际监查员', trigger: 'change' },
      },
      hospital_participantsList: [],
      sponsor_participantsList: [],
      drawer: false,
      form: {
        mail_content: '',
        sign_content: '',
        mail_from: store.state.user.userInfo.email,
        emailSet: store.state.user.userInfo.emailSet === 0,
        project_id: localStorage.project_id,
        hospital_id: localStorage.hospital_id,
        type: 'index1',
        pdf_name: '',
        pdf_path: '',
        mail_to: [],
        mail_cc: [],
        mail_title: ''
      },
      rules: {
        mail_to: { required: true, message: '请选择收件地址', trigger: 'change' },
        mail_title: { required: true, message: '请输入邮件主题', trigger: 'blur' },
        mail_content: { required: true, message: '请输入正文内容', trigger: 'blur' },
      }
      ,
      editor: null,
      autographEditor: null,
      editorConfig: {
        MENU_CONF: {}
      },
      toolbarConfig: {
        excludeKeys: ['group-image', 'fullScreen']
      },
      emailListDrawer: false,
      emailLogs: [],
      emailLogsForm: {
        project_id: localStorage.project_id,
        hospital_id: localStorage.hospital_id,
        type: '',
        plan_id: 0,
        page: 1,
        pageSize: 20
      },
      emailListTotal: 0,
      annotations: false,
      annotationsValue: '',
      viewPDFVisible: false,
      src: '',
      field_id: '',
      annList: [],
      content: '',
      project_id: localStorage.project_id,
      emailList: [],
      approval: {
        title: '',
        approval_status: '',
        remark: ''
      },
      approvalForm: {
        approval_status: [
          { required: true, message: '请选择审批结果', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async initData () {
      const { data } = await hospitalplandetail({ project_id: localStorage.project_id, hospital_id: this.row.hospital_id, id: this.row.id, plan_id: this.row.plan_id })
      this.viewObject = data
      this.zhixingForm.start_date = data.start_date
      this.zhixingForm.end_date = data.end_date
      this.zhixingForm.real_hours = data.real_hours
      this.zhixingForm.real_cra = data.real_cra
      this.zhixingForm.real_collaborative_personnel = data.real_collaborative_personnel
      this.zhixingForm.real_collaborative_personnel_label = data.real_collaborative_personnel_label
      this.zhixingForm.real_hospital_participants = data.real_hospital_participants
      this.zhixingForm.real_sponsor_participants = data.real_sponsor_participants
      this.zhixingForm.real_cra_label = data.real_cra_label
      this.zhixingForm.real_hospital_participants_label = data.real_hospital_participants_label
      this.zhixingForm.real_sponsor_participants_label = data.real_sponsor_participants_label
      if (this.approvalType === 'MonitoringPlan') {
        const { data: resultList } = await hospitalplanapprovalDetail({ project_id: localStorage.project_id, hospital_id: this.row.hospital_id, id: this.row.id, plan_id: this.row.plan_id })
        this.resultList = resultList
      }
      if (this.approvalType === 'MonitoringReport') {
        const { data: resultList } = await hospitalplanReportDetail({ project_id: localStorage.project_id, hospital_id: this.row.hospital_id, id: this.row.id, plan_id: this.row.plan_id })
        this.resultList = resultList
      }
      this.viewObject.project_name = localStorage.project_name
      this.viewObject.hospital_id = localStorage.hospital_id
      this.viewObject.hospital_name = localStorage.hospital_name
      const { data: hospital_participantsList } = await projectHospitalContactsList({ project_id: localStorage.project_id, hospital_id: localStorage.hospital_id, page: 1, pageSize: 999999 })
      this.hospital_participantsList = hospital_participantsList.data
      const { data: sponsor_participantsList } = await projectContactsList({ project_id: localStorage.project_id, hospital_id: localStorage.hospital_id, page: 1, pageSize: 999999 })
      this.sponsor_participantsList = sponsor_participantsList.data
      const { data: members } = await projectconfigmembersList({ project_id: localStorage.project_id, hospital_id: localStorage.hospital_id, position: 'CRA' })
      this.members = members
      const { data: emailList } = await emailContactsList({ project_id: localStorage.project_id, hospital_id: localStorage.hospital_id })
      this.emailList = emailList
    },
    async zhixingSave () {
      const real_cra_label = []
      for (const m of this.zhixingForm.real_cra) {
        real_cra_label.push(this.members.find(i => i.uid == m)?.user_name)
      }
      this.zhixingForm.real_cra_label = real_cra_label.join(',')
      const real_hospital_participants_label = []
      for (const m of this.zhixingForm.real_hospital_participants) {
        real_hospital_participants_label.push(this.hospital_participantsList.find(i => i.id == m)?.name)
      }
      this.zhixingForm.real_hospital_participants_label = real_hospital_participants_label.join(',')
      const real_sponsor_participants_label = []
      for (const m of this.zhixingForm.real_sponsor_participants) {
        real_sponsor_participants_label.push(this.sponsor_participantsList.find(i => i.id == m)?.name)
      }
      this.zhixingForm.real_sponsor_participants_label = real_sponsor_participants_label.join(',')
      try {
        await this.$refs.zhixingForm.validate()
        const data = await executeInfoSave({ ...this.zhixingForm, id: this.row.id, plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id })
        if (data.code == 0) {
          this.zhixing = true
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectUser () {
      this.$projectDialog.selectUser({ type: false }).then(res => {
        if (res.done) {
          this.zhixingForm.real_cra = res.data.map(i => i.id)
          this.zhixingForm.real_cra_label = res.data.map(i => i.name).join(',')
        }
      })
    },
    selectUser2 () {
      this.$projectDialog.selectUser({ type: false }).then(res => {
        if (res.done) {
          this.zhixingForm.real_collaborative_personnel = res.data.map(i => i.id)
          this.zhixingForm.real_collaborative_personnel_label = res.data.map(i => i.name).join(',')
        }
      })
    },
    initHttp (plan_id, project_id, hospital_id, file_type, template_type) {
      var _this = this
      //取消时的资源标记
      this.source = axios.CancelToken.source()
      return reportDownload(
        { plan_id: plan_id, project_id, hospital_id, file_type, template_type },
        function (ProgressEvent) {
          const load = ProgressEvent.loaded
          const total = ProgressEvent.total
          const progress = (load / total) * 100
          //一开始已经在计算了 这里要超过先前的计算才能继续往下
          if (progress > _this.percentage) {
            _this.percentage = Math.floor(progress)
          }
          if (progress == 100) {
            //下载完成
            _this.downloadVisible = false
          }
        },
        this.source.token,//声明一个取消请求token
      )
    },
    download (plan_id, project_id, hospital_id, file_type, template_type) {
      this.downloadVisible = true
      this.percentage = 0
      this.initHttp(plan_id, project_id, hospital_id, file_type, template_type).then(res => {
        if (res.size == 0) {
          return this.$alert("下载失败")
        }
        const blob = new Blob([res.data])
        if ("download" in document.createElement("a")) {
          const elink = document.createElement("a")
          elink.download = res.fileName
          elink.style.display = "none"
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(function () {
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
          }, 100)
        } else {
          navigator.msSaveBlob(blob, res.fileName)
        }
      }).catch(error => {
        this.downloadVisible = false
        console.info(error)
      })
    },
    downClose () {
      this.source.cancel('log==客户手动取消下载')
      this.downloadVisible = false
    },
    sendEmail (pdf_name, pdf_path) {
      this.form.pdf_name = pdf_name
      this.form.pdf_path = pdf_path
      this.drawer = true
    },
    onCreated (editor) {
      this.editor = Object.seal(editor)
    },
    onCreatedAutograph (editor) {
      this.autographEditor = Object.seal(editor)
    },
    closeDrawer () {
      this.drawer = false
    },
    emailSetHandle () {
      this.visible = false
      this.drawer = false
      router.push('/project/setMail')
    },
    async onSendMailSave () {
      if (store.state.user.userInfo.emailSet == 0) {
        return this.$message.error({ message: '请先配置寄件地址' })
      }
      try {
        await this.$refs.form.validate();
        this.form.plan_id = this.row.id
        const data = await sendMailSave(this.form)
        if (data.code === 0) {
          this.drawer = false
        }
      } catch (error) {
        console.log(error);
      }

    },
    async sendEmailList () {
      this.emailLogsForm.type = this.form.type
      this.emailLogsForm.plan_id = this.row.id
      const { data: emailLogs } = await sendMailList(this.emailLogsForm)
      this.emailListTotal = emailLogs.total
      this.emailLogs = emailLogs.data
      this.emailListDrawer = true
    },
    handleSizeChange (val) {
      this.emailLogsForm.pageSize = val
      this.sendEmailList()
    },
    handleCurrentChange (val) {
      this.emailLogsForm.page = val
      this.sendEmailList()
    },
    viewEmail (row) {
      this.$alert(row.mail_content, '邮件内容', {
        dangerouslyUseHTMLString: true
      });
    },
    handleViewPDFVisible (src) {
      this.viewPDFVisible = true
      this.src = src
    },
    async sumbitApproval () {
      try {
        await this.$refs.approvalForm.validate()
        let data = {}
        if (this.approvalType == 'MonitoringPlan') {
          data = await projectplanapproval(this.approval)
        }
        if (this.approvalType == 'MonitoringReport') {
          data = await planReportApproval(this.approval)
        }
        if (data.code === 0) {
          this.visible = false
          this.callback({ done: true })
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  created () {
    this.initData()
    this.approval.title = this.row.title
    this.approval.plan_id = this.row.id
    this.approval.workflow_id = this.row.workflow_id
    this.approval.project_id = localStorage.project_id
  },
  mounted () {
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.el-card {
	margin-bottom: 15px;
}
::v-deep .el-drawer__header {
	margin-bottom: 0px;
	padding-bottom: 20px;
	border-bottom: solid 1px #dcdfe6;
}

.viewDialog {
	min-width: 1024px;
	.viewTitle {
		color: rgb(128, 128, 128);
		font-weight: 500;
		font-size: 14px;
	}
	::v-deep .el-dialog__header {
		padding: 15px;
		box-shadow: 0 1px 13px 0 rgb(31 56 88 / 15%);
	}
	.viewHospital {
		color: rgb(164, 164, 164);
		font-size: 12px;
		padding-top: 8px;
	}
	::v-deep .el-dialog__body {
		padding: 20px 15px;
		height: calc(100% - 73px);
	}
	.tabTitle {
		padding: 10px 0px 15px;
		display: flex;
		justify-content: space-between;
	}
	.tabContent {
		padding: 15px;
	}
}
.viewHeader {
	display: flex;
	.viewSpan {
		line-height: 43px;
		color: rgba(0, 0, 0, 0.85);
	}
}
::v-deep .el-divider--vertical {
	height: 43px;
	margin: 0px 20px;
}

.viewUL {
	list-style: none;
	list-style-type: none;
	counter-reset: sectioncounter;
	li {
		margin-bottom: 12px;
	}
	li:before {
		content: counter(sectioncounter) '、';
		counter-increment: sectioncounter;
		font-family: HelveticaNeue-Bold, HelveticaNeue;
	}
}
.content {
	display: flex;
	height: 100%;

	.left {
		word-break: break-all;
		padding: 5px;
		width: 40%;
		border-right: 1px solid #e4e7ed;
		text-overflow: ellipsis;
	}

	.right {
		word-break: break-all;
		padding: 5px;
		width: 60%;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 225);
	}
}

.fullwidth {
	width: 100%;
}
.el-tab-pane {
	height: 100%;
}
.el-tabs {
	height: 100%;
	::v-deep .el-tabs__content {
		height: calc(100% - 39px);
	}
}
.send {
	padding: 10px;
	height: 100%;
}
.footer {
	display: flex;
	justify-content: flex-end;
	padding: 15px 15px 0 0;
}
.el-message-box {
	width: auto !important;
	height: auto;
}
.demo-drawer__footer {
	display: flex;
	border-top: solid 1px #dcdfe6;
	padding: 10px;
	flex-direction: column;
}
.footerBtn {
	display: flex;
	padding-top: 10px;
}
.demo-drawer__footer button {
	flex: 1;
}
.demo-drawer__content {
	display: flex;
	flex-direction: column;
	height: 100%;
}
::v-deep .el-dialog__body {
	height: 95%;
}
.annList {
	flex: 1;
	height: 100%;
	padding: 15px;
	overflow-y: auto;
}
.tabContent .el-form-item {
	width: 50%;
	float: left;
}
.shenpi {
	display: flex;
	justify-content: space-evenly;
}
</style>