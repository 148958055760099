export default [
  {
    path: '/worktimeDingDing',
    component: () => import(/* webpackChunkName: 'worktimeDingDing' */'@/views/worktimeDingDing/index'),
    meta: { requiresAuth: true },
  },
  {
    path: '/worktimeDingDing/approval',
    component: () => import(/* webpackChunkName: 'worktimeDingDingApproval' */'@/views/worktimeDingDing/approval'),
    meta: { requiresAuth: true },
  },
  {
    path: '/worktimeDingDing/empty',
    component: () => import(/* webpackChunkName: 'worktimeDingDingjump' */'@/views/worktimeDingDing/empty'),
    meta: { requiresAuth: false },
  }
]