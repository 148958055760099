import Vue from 'vue'
// 导入组件
import addCodefileDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个“子类”
const AddCodefileConstructor = Vue.extend(addCodefileDialog)

let addCodefileInstance = null

// type 类型 meddra 还是 whodd
const showAddCodefileDialog = (type) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (addCodefileInstance !== null) {
    document.body.removeChild(addCodefileInstance.$el)
  }
  return new Promise((reslove) => {
    addCodefileInstance = new AddCodefileConstructor({
      el: document.createElement('div'),
      data () {
        return { type }
      }
    })
    addCodefileInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res.done && addCodefileInstance !== null) {
        document.body.removeChild(addCodefileInstance.$el)
        addCodefileInstance = null
      }
    }
    document.body.appendChild(addCodefileInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      addCodefileInstance.visible = true //  打开对话框
    })
  })
}

export default showAddCodefileDialog
