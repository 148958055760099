<template>
	<TablePage>
		<template #header>
			<div class="pageHeader">
				<p>MedDRA医学辞典</p>
				<el-form
					:inline="true"
					:model="form"
					ref="form"
					class="demo-form-inline"
				>
					<el-form-item label="语言" prop="language" v-if="from != 'editcode'">
						<el-select v-model="form.language" placeholder="请选择语言">
							<el-option
								v-for="item in langList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						v-if="['codelibrary', 'dictionary'].includes(from)"
						label="版本"
						prop="version"
					>
						<el-select v-model="form.version" placeholder="请选择版本">
							<el-option
								v-for="item in versionList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="低位语" prop="lltName">
						<el-input
							v-model="form.lltName"
							placeholder="输入低位语"
						></el-input>
					</el-form-item>
					<el-form-item label="低位语编码" prop="lltCode">
						<el-input
							v-model="form.lltCode"
							placeholder="输入低位语编码"
						></el-input>
					</el-form-item>
					<el-form-item label="现行" prop="currency">
						<el-select v-model="form.currency" placeholder="请选择现行">
							<el-option label="是" value="Y"></el-option>
							<el-option label="否" value="N"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<div style="display: flex">
							<el-button type="primary" @click="onSubmit" :loading="isloading"
								>查询</el-button
							>
							<el-button type="default" @click="resetQuery">重置</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</template>
		<template #table>
			<el-table
				:data="tableData"
				style="height: 100%"
				height="100%"
				v-loading="isloading"
				:highlight-current-row="['codelibrary', 'editcode'].includes(from)"
				@row-click="singleElection"
			>
				<el-table-column
					align="center"
					width="55"
					label="选择"
					v-if="['codelibrary', 'editcode'].includes(from)"
				>
					<template slot-scope="scope">
						<el-radio
							@click.native.prevent="return false"
							class="radio"
							v-model="lltCode"
							:label="scope.row.lltCode"
							>&nbsp;</el-radio
						>
					</template>
				</el-table-column>
				<el-table-column
					prop="MedDRAVersion"
					label="语言和版本"
					width="200"
					align="center"
				>
				</el-table-column>
				<el-table-column prop="currency" label="现行" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.currency == 'Y'">是</el-tag>
						<el-tag v-if="scope.row.currency == 'N'" type="warning">否</el-tag>
					</template>
				</el-table-column>

				<el-table-column prop="lltName" label="低位语" align="center">
				</el-table-column>
				<el-table-column prop="lltCode" label="低位语编码" align="center">
				</el-table-column>
				<el-table-column prop="ptName" label="首选术语" align="center">
				</el-table-column>
				<el-table-column prop="ptCode" label="首选术语编码" align="center">
				</el-table-column>
				<el-table-column prop="hltName" label="高位语" align="center">
				</el-table-column>
				<el-table-column prop="hltCode" label="高位语编码" align="center">
				</el-table-column>
				<el-table-column prop="hlgtName" label="高位组语" align="center">
				</el-table-column>
				<el-table-column prop="hlgtCode" label="高位组语编码" align="center">
				</el-table-column>
				<el-table-column prop="socName" label="主系统器官分类" align="center">
				</el-table-column>
				<el-table-column
					prop="socCode"
					label="主系统器官分类编码"
					align="center"
				>
				</el-table-column>
				<el-table-column prop="soc_abbrev" label="SOC缩略语" align="center">
				</el-table-column>
				<el-table-column prop="primary_soc" label="主SOC" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.primary_soc == 'Y'">是</el-tag>
						<el-tag v-if="scope.row.primary_soc == 'N'" type="warning"
							>否</el-tag
						>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<template #footer>
			<div class="footer">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="form.page"
					:page-sizes="[20, 100, 300, 500]"
					:page-size="form.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
					:disabled="isloading"
				>
				</el-pagination>
			</div>
		</template>
	</TablePage>
</template>

<script>
import TablePage from '_c/tablePage'
import { getMedDRA, getMedDRASetInfo } from '_s/medicode.js'
export default {
  name: 'medicodeMeddraDictionary',
  components: { TablePage },
  props: {
    // 从哪里打开的，page 页面，dialog 弹出层
    from: { required: true, type: String },
    version: { required: true, type: String },
    language: {}
  },
  data () {
    return {
      form: {
        lltCode: '',
        lltName: '',
        language: '',
        version: '',
        currency: '',
        page: 1,
        pageSize: 20,
      },
      isloading: false,
      tableData: [],
      total: 0,
      langList: [],
      versionList: [],
      langObj: {},
      lltCode: ''
    }
  },
  methods: {
    async loadSetInfo () {
      this.isloading = true
      const res = await getMedDRASetInfo()
      this.langObj = res.data.language
      for (let i in this.langObj) {
        this.langList.push({ label: this.langObj[i], value: i })
      }
      const v = res.data.version
      for (let i in v) {
        this.versionList.push({ label: v[i], value: i })
      }
    },
    async loadData () {
      this.isloading = true
      if (this.version) this.form.version = this.version
      if (this.from == 'editcode') this.form.primary_soc = 'Y'
      const { data } = await getMedDRA(this.form)
      this.tableData = data.data.map(i => {
        i.language = this.langObj[i.language]
        return i
      })
      this.total = data.total
      this.isloading = false
    },
    onSubmit () {
      this.form.page = 1
      this.loadData()
    },
    resetQuery () {
      this.$refs.form.resetFields()
      this.loadData()
    },
    handleSizeChange (val) {
      this.form.pageSize = val
      this.loadData()
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.loadData()
    },
    singleElection (row) {
      this.lltCode = row.lltCode
      this.$emit('changeColum', row)
    }
  },
  created () {
    if (this.language) this.form.language = this.language
    this.loadSetInfo().then(() => {
      this.loadData()
    })
  }
}
</script>
<style lang="scss" scoped>
.footer {
	display: flex;
	justify-content: flex-end;
	padding: 15px 15px 0 0;
}

.pageHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.demo-form-inline {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.el-form-item {
	margin-bottom: 0px;
}
</style>
