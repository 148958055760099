export default {
    path: '/statistic',
    component: () => import(/* webpackChunkName: 'layout' */'@/views/statistic/layout/index'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'statistic',
        component: () => import(/* webpackChunkName: 'user' */'@/views/statistic/index')
      }
    ]
  }