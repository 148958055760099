import Vue from 'vue'
// 导入组件
import AddUserGroupDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个“子类”
const AddUserGroupConstructor = Vue.extend(AddUserGroupDialog)

let addUserGroupInstance = null
/**
 * 初始化用户组信息实例
 * @param {string} name 用户组名称
 * @param {number} type 1-新建，0-修改
 * @param {number} groupId 组id
 */
const initInstanceAddFolder = (name, type, groupId, status) => {
  addUserGroupInstance = new AddUserGroupConstructor({
    el: document.createElement('div'),
    data () {
      return {
        name, type, groupId, status
      }
    }
  })
}
/**
 * 用户组信息实例 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
const showAddUserGroupDialog = ({ name, type, groupId, status }) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (addUserGroupInstance !== null) {
    document.body.removeChild(addUserGroupInstance.$el)
  }
  return new Promise((reslove) => {
    initInstanceAddFolder(name, type, groupId, status)
    addUserGroupInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res === 'cancel' && addUserGroupInstance !== null) {
        document.body.removeChild(addUserGroupInstance.$el)
        addUserGroupInstance = null
      }
    }
    document.body.appendChild(addUserGroupInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      addUserGroupInstance.visible = true //  打开对话框
    })
  })
}

export default showAddUserGroupDialog
