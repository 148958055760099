<template>
  <pre class="code-area">{{ value }}</pre>
</template>

<script>
export default {
  name: 'CodeEditor',
  props: {
    value: {
      type: String,
      description: '值'
    }
  },
};
</script>

<style scoped>
.code-area {
  display: block;
  margin: 0 auto;
  font-size: 12px;
  margin: 0 auto;
  background: #1f1f1f;
  word-break: break-word;
  white-space: break-spaces;
  color: #ffffff;
  padding: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.42857143;
}
</style>
