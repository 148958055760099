<template>
  <div>
    <el-dialog :visible.sync="visible" fullscreen :modal="false" :destroy-on-close="true" :show-close="false" center>
      <templates :type="true" @changeColum="changeColum"></templates>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">提 交 选 择</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import templates from '_v/crm/templates/index.vue';
export default {
  components: {
    templates
  },
  data () {
    return {
      visible: false,
      row: null
    }
  },
  methods: {
    changeColum (row) {
      this.row = row
    },
    handleSubmit () {
      if (!this.row) return this.$message.error({ message: '请先选择模板！' })
      this.visible = false
      this.callback({ done: true, row: this.row })
    }
  }

}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 88vh;
  overflow: hidden;
}
</style>