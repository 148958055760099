const data = {
  token: null, // 用户token
  remember: null,// 记住的账号密码
  userInfo: null,//用户信息
  menuUri: null,
  project_type: null,
  project_id: null,// 点击的项目id
  project_name: null, // 点击的项目名
  project_number: null, // 点击的项目编码
  hospital_id: null, // 项目下准备要显示的中心id
  hospital_name: null // 项目下准备要显示的中心名
}
Object.keys(data).forEach(key => {
  Object.defineProperty(data, key, {
    get: () => {
      if (window.localStorage.getItem(`${key}`) == 'undefined') return null
      const str = window.localStorage.getItem(`${key}`)
      try {
        return JSON.parse(str)
      } catch (error) {
        return str
      }
    },
    set: (value) => {
      if (typeof value != 'string' && typeof value != 'number') {
        value = JSON.stringify(value)
      }
      window.localStorage.setItem(`${key}`, value)
    }
  })
})
export default data
