<template>
  <el-dialog :visible.sync="visible" fullscreen :close-on-press-escape="false" :show-close="false" center>
    <div class="content">
      <div class="left">
        <whoddCode from="editcode" :drug_Code="whodd.drug_code" :language="language" :version="version"
          @changeColum="changeColum">
        </whoddCode>
      </div>
      <div class="right">
        <div class="r-header">
          <el-descriptions :title="whodd.name" class="margin-top" :column="3" border>
            <el-descriptions-item>
              <template slot="label">
                编码编号
              </template>
              {{ whodd.id }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                版本
              </template>
              {{ version }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                给药/接种途径
              </template>
              {{ whodd.administration }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                用药/接种原因1
              </template>
              {{ whodd.medication1 }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                用药/接种原因2
              </template>
              {{ whodd.medication2 }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                用药/接种原因3
              </template>
              {{ whodd.medication3 }}
            </el-descriptions-item>
          </el-descriptions>
          <el-divider></el-divider>
          <el-descriptions class="margin-top" :column="1">
            <el-descriptions-item>
              <template slot="label">
                <b style="color:#409eff;">药物名称B3</b>
              </template>
              {{ drugName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <b style="color:#409eff;">药物编码</b>
              </template>
              {{ drugCode }}
            </el-descriptions-item>
          </el-descriptions>
          <el-divider></el-divider>
        </div>
        <div class="r-table">
          <TablePage>
            <template #table>
              <el-table :data="atcData" border style="width: 100%" v-loading="isloading" :highlight-current-row="true"
                @row-click="singleElection">
                <el-table-column align="center" width="55" label="选择">
                  <template slot-scope="scope">
                    <el-radio @click.native.prevent="return false" class="radio" v-model="atc_code"
                      :label="scope.row.atc_code">&nbsp;</el-radio>
                  </template>
                </el-table-column>
                <el-table-column label="atc">
                  <template slot-scope="scope">
                    <span>{{ scope.row.atc_code }}：{{ scope.row.atc_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="atc1">
                  <template slot-scope="scope">
                    <span>{{ scope.row.atc1_code }}：{{ scope.row.atc1_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="atc2">
                  <template slot-scope="scope">
                    <span>{{ scope.row.atc2_code }}：{{ scope.row.atc2_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="atc3">
                  <template slot-scope="scope">
                    <span>{{ scope.row.atc3_code }}：{{ scope.row.atc3_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="atc4">
                  <template slot-scope="scope">
                    <span>{{ scope.row.atc4_code }}：{{ scope.row.atc4_name }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </TablePage>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="btn" @click="visible = false">取 消</el-button>
      <el-checkbox v-model="batchUpdate" label="批量更新" border></el-checkbox>
      <el-button type="primary" :loading="btnLoad" @click="handleSubmit">提 交 选 择</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TablePage from '_c/tablePage';
import whoddCode from '_c/medicode/whoddCode';
import { getWhoDDATCData, updateWhoDDData } from '_s/medicode';
export default {
  components: {
    whoddCode,
    TablePage
  },
  data () {
    return {
      visible: false,
      batchUpdate: '',
      drugCode: '',
      drugName: '',
      isloading: false,
      atcData: [],
      atc_code: '',
      btnLoad: false
    }
  },
  methods: {
    async handleSubmit () {
      if (!this.atc_code) {
        this.$message.error({ message: '请选择ATC编码!' })
        return false
      }
      this.btnLoad = true
      const data = {
        id: this.id,
        taskId: this.taskId,
        language: this.language,
        version: this.version,
        administration: this.whodd.administration,
        drug_code: this.drugCode,
        atc: this.atc_code,
        batchUpdate: this.batchUpdate ? 1 : 0,
        medication1: this.whodd.medication1,
        medication2: this.whodd.medication2,
        medication3: this.whodd.medication3
      }
      const res = await updateWhoDDData(data)
      if (res.code === 0) {
        this.visible = false
        this.callback({ done: true })
      }
      this.btnLoad = false
    },
    async changeColum (row, callback) {
      if (!row) {
        this.atcData = []
        this.drugCode = ''
        this.drugName = ''
        return false
      }
      this.drugCode = row.drugCode
      this.drugName = row.drugName
      this.isloading = true
      const atc = row.atc.map(i => i.code)
      const { data } = await getWhoDDATCData({ atc, language: this.language, version: this.version })
      this.atcData = data
      callback()
      this.isloading = false
    },
    singleElection (row) {
      this.atc_code = row.atc_code
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  height: 95%;
}

::v-deep .el-dialog__footer {
  padding: 0;
}

::v-deep .el-checkbox,
.btn {
  margin-right: 10px !important;
}

.content {
  display: flex;
  height: 100%;

  .left {
    width: 50%;
  }

  .right {
    width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .r-table {
    flex: 1;
  }
}
</style>