<template>
	<el-dialog :visible.sync="visible" fullscreen>
		<el-tabs>
			<el-tab-pane label="查看方案违背">
				<div>
					<el-descriptions class="margin-top" title="基本信息" :column="2">
						<el-descriptions-item label="PD编号">{{
							viewObj.pd_number
						}}</el-descriptions-item>
						<el-descriptions-item label="受试者">{{
							viewObj.patientLabel
						}}</el-descriptions-item>
						<el-descriptions-item label="PD范围">{{
							viewObj.range_name
						}}</el-descriptions-item>
						<el-descriptions-item label="PD类型">{{
							viewObj.type_name
						}}</el-descriptions-item>
						<el-descriptions-item label="PD等级">{{
							viewObj.level
						}}</el-descriptions-item>
						<el-descriptions-item label="内部上报">
							{{ viewObj.internal_reporting === 1 ? '是' : (viewObj.internal_reporting === 2 ? '否' : '不使用') }}
							</el-descriptions-item>
						<el-descriptions-item label="PD描述">{{
							viewObj.pd_description
						}}</el-descriptions-item>
						<el-descriptions-item label="受试者是否可以继续参加研究">
							{{ viewObj.patient_continue === 1 ? '是' : '否' }}
							</el-descriptions-item>
						<el-descriptions-item label="发生日期">{{
							viewObj.occurrence_date
						}}</el-descriptions-item>
						<el-descriptions-item label="发现时间">{{
							viewObj.discovery_time
						}}</el-descriptions-item>
						<el-descriptions-item label="发现时间">{{
							viewObj.discovery_time
						}}</el-descriptions-item>
						<el-descriptions-item label="是否停用试验药物">
							{{ viewObj.drug_stop === 1 ? '是' : '否' }}
							</el-descriptions-item>
						<el-descriptions-item label="是否需跟进">
							{{ viewObj.follow_up === 1 ? '是' : "否"}}
							</el-descriptions-item>
						<el-descriptions-item label="采取措施">{{
							viewObj.take_steps
						}}</el-descriptions-item>
						<!-- <el-descriptions-item label="是否为重大PD">
							{{ viewObj.pd_major === 11 ? '疑是' : (viewObj.pd_major === 1 ? '是' : '否') }}
							</el-descriptions-item> -->
					</el-descriptions>
					<el-divider></el-divider>
					<el-descriptions class="margin-top" title="PD跟踪" :column="2">
						<!-- <el-descriptions-item label="采取措施">{{
							viewObj.plan_tracks
						}}</el-descriptions-item> -->
						<template v-for="(item, index) in viewObj.plan_tracks">		
							<el-descriptions-item label="情况跟踪" :key="index + 'tr'">{{
								item.situation_tracking
							}}</el-descriptions-item>
							<el-descriptions-item label="跟踪日期" :key="index + 'da'">{{
								item.plan_resolution_date
							}}</el-descriptions-item>
						</template>
					</el-descriptions>
					<el-divider></el-divider>
					<el-descriptions class="margin-top" title="解决情况" :column="2">
						<!-- <el-descriptions-item label="采取措施">{{
							viewObj.take_steps
						}}</el-descriptions-item> -->
						<!-- <el-descriptions-item label="情况跟踪">{{
							viewObj.situation_tracking
						}}</el-descriptions-item> -->
						<el-descriptions-item label="实际解决日期">{{
							viewObj.resolution_date
						}}</el-descriptions-item>
						<el-descriptions-item label="预计解决日期">{{
							viewObj.plan_resolution_date
						}}</el-descriptions-item>
						<!-- <el-descriptions-item label="是否为重大PD">
							<el-select
								v-if="viewObj.internal_reporting"
								v-model="viewObj.pd_major"
								size="mini"
								disabled
							>
								<el-option label="疑是" :value="11"> </el-option>
								<el-option label="是" :value="1"> </el-option>
								<el-option label="否" :value="2"> </el-option> </el-select
						></el-descriptions-item> -->
						<!-- <el-descriptions-item label="是否解决">
							<el-select
								v-if="viewObj.internal_reporting"
								v-model="viewObj.solve"
								size="mini"
								disabled
							>
								<el-option label="是" :value="1"> </el-option>
								<el-option label="否" :value="2"> </el-option> </el-select
						></el-descriptions-item> -->
						<el-descriptions-item label="解决日期">{{
							viewObj.resolution_date
						}}</el-descriptions-item>
						<el-descriptions-item label="是否报告伦理">
							{{ viewObj.drug_stop === 1 ? '是' : '否' }}
							</el-descriptions-item>
						<el-descriptions-item v-if="viewObj.report_ethics_date" label="报告伦理日期">{{
							viewObj.report_ethics_date
						}}</el-descriptions-item>
						<el-descriptions-item label="解决结果">{{
							viewObj.resolution_result
						}}</el-descriptions-item>
					</el-descriptions>
				</div>
			</el-tab-pane>
			<el-tab-pane label="查看审批">
				<el-empty
					description="暂无数据"
					v-if="resultList.length < 1"
				></el-empty>
				<el-card class="box-card" v-for="card in resultList" :key="card.title">
					<h4>{{ card.title }}</h4>
					<p>{{ card.userName }}&nbsp;{{ card.sumbitTime | dateFormat }}</p>
					<br />
					<el-table :data="card.table" border style="width: 100%">
						<el-table-column prop="node" label="审批节点"> </el-table-column>
						<el-table-column prop="user_name" label="处理人"> </el-table-column>
						<el-table-column label="操作时间">
							<template slot-scope="scope">
								{{ scope.row.createdAt | dateFormat }}
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审批结果">
							<template slot-scope="scope">
								<p class="markerLine" v-if="scope.row.status === 0">
									<span class="marker"> </span>
									未处理
								</p>
								<p class="markerLine" v-if="scope.row.status === 2">
									<span class="marker-green"> </span>
									通过
								</p>
								<p class="markerLine" v-if="scope.row.status === 3">
									<span class="marker-red"> </span>
									驳回
								</p>
							</template>
						</el-table-column>
						<el-table-column prop="remarks" label="意见"> </el-table-column>
					</el-table>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="审批" v-if="row.status == 1">
				<el-form :model="approval" :rules="approvalForm" ref="approvalForm">
					<el-form-item label="任务：" label-width="120px">
						{{ approval.title }}
					</el-form-item>
					<el-form-item
						prop="approval_status"
						label="提交审批："
						label-width="120px"
					>
						<el-select
							v-model="approval.approval_status"
							placeholder="请选择审批结果"
							style="width: 100%"
						>
							<el-option label="通过" :value="2"></el-option>
							<el-option label="驳回" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="审批意见：" label-width="120px">
						<el-input v-model="approval.remark" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item class="shenpi">
						<el-button type="primary" @click="sumbitApproval"
							>提交审批</el-button
						>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
	</el-dialog>
</template>

<script>
import {
  pddetail,
  pdapprovalPDDetail,
  patientlist,
  pdapproval
} from '_s/project';
export default {
  data () {
    return {
      viewObj: {},
      resultList: [],
      visible: false,
      patientList: [],
      approval: {
        title: '',
        approval_status: '',
        remark: ''
      },
      approvalForm: {
        approval_status: [
          { required: true, message: '请选择审批结果', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async initData () {
      const { data } = await pddetail({ project_id: localStorage.project_id, hospital_id: this.row.hospital_id, id: this.row.id })
			if (!data.plan_tracks) {
				data.plan_tracks = [
					{
						situation_tracking: '',
						plan_resolution_date: ''
					}
				]
			}
      this.viewObj = data
      const { data: patientList } = await patientlist({ project_id: localStorage.project_id, hospital_id: localStorage.hospital_id, page: 1, pageSize: 999999 })
      this.patientList = patientList.data
      this.viewObj.patientLabel = ''
      if (this.viewObj.patient_id.length > 0) {
        this.viewObj.patientLabel = this.viewObj.patient_id.map(v => this.patientList.find(f => f.id === v).patient_number).join(',')
      }
      const { data: resultList } = await pdapprovalPDDetail({ project_id: localStorage.project_id, hospital_id: this.row.hospital_id, id: this.row.id })
      this.resultList = resultList
    },
    async sumbitApproval () {
      try {
        await this.$refs.approvalForm.validate()
        const data = await pdapproval(this.approval)
        if (data.code === 0) {
          this.visible = false
          this.callback({ done: true })
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  created () {
    this.initData()
    this.approval.pd_id = this.row.id
    this.approval.pd_workflow_id = this.row.workflow_id
    this.approval.title = this.row.title
    this.approval.project_id = localStorage.project_id
  }
}
</script>

<style lang="scss" scoped>
.el-card {
	margin-bottom: 15px;
}
.shenpi {
	display: flex;
	justify-content: space-evenly;
}
</style>