<template>
  <div class="content"><el-image :src="image" fit="contain"></el-image></div>
</template>

<script>
export default {
  name: 'imageViewer',
  props: {
    image: String,
  }
}
</script>
<style lang="scss" scoped>
.content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>