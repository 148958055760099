export default {
  path: '/worktime',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/worktime/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'my',
      component: () => import(/* webpackChunkName: 'my' */'@/views/worktime/index')
    },
    {
      path: '/worktime/approval',
      name: 'approval',
      component: () => import(/* webpackChunkName: 'approval' */'@/views/worktime/approval')
    },
    {
      path: '/worktime/week',
      name: 'week',
      component: () => import(/* webpackChunkName: 'set' */'@/views/worktime/week')
    },
    {
      path: '/worktime/set',
      name: 'set',
      component: () => import(/* webpackChunkName: 'set' */'@/views/worktime/set')
    },
    {
      path: '/worktime/staffStatistics',
      name: 'staffStatistics',
      component: () => import(/* webpackChunkName: 'staffStatistics' */'@/views/worktime/staffStatistics')
    },
    {
      path: '/worktime/projectStatistics',
      name: 'projectStatistics',
      component: () => import(/* webpackChunkName: 'projectStatistics' */'@/views/worktime/projectStatistics')
    },
    {
      path: '/worktime/projectStatisticsList',
      name: 'projectStatisticsList',
      component: () => import(/* webpackChunkName: 'projectStatistics' */'@/views/worktime/projectStatisticsList')
    },
    {
      path: '/worktime/projectStaffTotalStatistics',
      name: 'projectStaffTotalStatistics',
      component: () => import(/* webpackChunkName: 'projectStatistics' */'@/views/worktime/projectStaffTotalStatistics')
    },
    {
      path: '/worktime/staffProjectTotalStatistics',
      name: 'staffProjectTotalStatistics',
      component: () => import(/* webpackChunkName: 'projectStatistics' */'@/views/worktime/staffProjectTotalStatistics')
    },
    {
      path: '/worktime/businessSet',
      name: 'businessSet',
      component: () => import(/* webpackChunkName: 'businessSet' */'@/views/worktime/set/businessSet')
    },
    {
      path: '/worktime/researchSet',
      name: 'researchSet',
      component: () => import(/* webpackChunkName: 'researchSet' */'@/views/worktime/set/researchSet')
    },
    {
      path: '/worktime/userUsage',
      name: 'userUsage',
      component: () => import(/* webpackChunkName: 'userUsage' */'@/views/worktime/userUsage')
    },
    {
      path: '/worktime/monthPlan',
      name: 'monthPlan',
      component: () => import(/* webpackChunkName: 'monthPlan' */'@/views/worktime/monthPlan')
    },
    {
      path: '/worktime/approvalMonthPlan',
      name: 'approvalMonthPlan',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/approvalMonthPlan')
    },
    {
      path: '/worktime/holidays',
      name: 'holidays',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/holidays')
    },
    {
      path: '/worktime/planMonthStatistics',
      name: 'planMonthStatistics',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/planMonthStatistics')
    },
    {
      path: '/worktime/userMonthFillStatistics',
      name: 'userMonthFillStatistics',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/userMonthFillStatistics')
    },
    {
      path: '/worktime/projectTotalStatistics',
      name: 'projectTotalStatistics',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/projectTotalStatistics')
    },
    {
      path: '/worktime/departmentMonthFillStatistics',
      name: 'departmentMonthFillStatistics',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/departmentMonthFillStatistics')
    },
    {
      path: '/worktime/projectPositionStatistics',
      name: 'projectPositionStatistics',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/projectPositionStatistics')
    },
    {
      path: '/worktime/staffProjectTotalStatistics2',
      name: 'staffProjectTotalStatistics2',
      component: () => import(/* webpackChunkName: 'approvalMonthPlan' */'@/views/worktime/staffProjectTotalStatistics2')
    },
    {
      path: '/worktime/projectManageStatisics',
      name: 'projectManageStatisics',
      component: () => import(/* webpackChunkName: 'projectManageStatisics' */'@/views/worktime/projectManageStatisics')
    },
    {
      path: '/worktime/departmentBudgetStatisics',
      name: 'departmentBudgetStatisics',
      component: () => import(/* webpackChunkName: 'departmentBudgetStatisics' */'@/views/worktime/departmentBudgetStatisics')
    },
    {
      path: '/worktime/staffProjectFTEStatistics',
      name: 'staffProjectFTEStatistics',
      component: () => import(/* webpackChunkName: 'staffProjectFTEStatistics' */'@/views/worktime/staffProjectFTEStatistics')
    },
  ]
}