<template>
	<el-dialog :visible.sync="visible" :show-close="false" :fullscreen="true">
		<TablePage>
			<template #header></template>
			<template #table>
				<el-table
					ref="wtData"
					:data="wtData"
					height="100%"
					v-loading="isloading"
					@row-click="singleElection"
					:modal="false"
				>
					<el-table-column align="center" width="55" label="选择">
						<template slot-scope="scope">
							<!-- 可以手动的修改label的值，从而控制选择哪一项 -->
							<el-radio
								class="radio"
								v-model="templateSelection"
								:label="scope.row"
								>&nbsp;</el-radio
							>
						</template>
					</el-table-column>
					<el-table-column
						prop="name"
						label="里程碑模版名称"
						align="center"
						min-width="250"
					>
					</el-table-column>
					<el-table-column prop="user_name" label="创建人" align="center">
					</el-table-column>
					<el-table-column prop="createdAt" label="创建时间" align="center">
						<template slot-scope="scope">
							{{ scope.row.createdAt | dateFormat }}
						</template>
					</el-table-column>
					<el-table-column
						prop="update_user_name"
						label="修改人"
						align="center"
					>
					</el-table-column>
					<el-table-column prop="updatedAt" label="修改时间" align="center">
						<template slot-scope="scope">
							{{ scope.row.updatedAt | dateFormat }}
						</template>
					</el-table-column>
				</el-table>
			</template>
			<template #footer>
				<div class="btns">
					<el-button @click="visible = false" size="small">取 消</el-button>
					<el-button
						type="primary"
						@click="selectTmp"
						size="small"
						:loading="btnLoading"
						>确 定</el-button
					>
				</div>
			</template>
		</TablePage>
	</el-dialog>
</template>

<script>
import TablePage from '_c/tablePage';
import { getMilestoneList, getDetailListByMilestone } from '_s/project';
export default {
  components: {
    TablePage
  },
  data () {
    return {
      visible: false,
      wtData: [],
      form: {
        name: '',
        type: ''
      },
      templateSelection: false,
      isloading: false,
      btnLoading: false
    }
  },
  methods: {
    async initData () {
      this.isloading = true
      const { data } = await getMilestoneList(this.form)
      this.wtData = data
      this.isloading = false
    },
    singleElection (row) {
      this.templateSelection = row
    },
    async selectTmp () {
      if (!this.templateSelection) return this.$message.error({ message: '至少选择一个模板！' })
      this.btnLoading = true
      const { data } = await getDetailListByMilestone({ template_id: this.templateSelection.id })
      this.btnLoading = false
      this.visible = false
      this.callback({ done: true, data: { template_id: this.templateSelection.id, milestones: data } })
    }
  },
  created () {
    this.form.type = this.type
    this.initData()
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
	height: 100%;
}
::v-deep .el-dialog__header {
	padding: 0;
}
.btns {
	padding: 10px;
	padding-bottom: 0px;
	text-align: center;
}
</style>