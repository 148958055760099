/**
 * 以服务的方式，将对文件的一些操作挂载到 Vue 上 , $openDialog - 各个服务的名称为其对应的文件夹的名称
 * 例如，删除文件功能：服务封装路径 '_c/file/dialog/deleteFile' ，则如下：
 * 在 *.vue 文件中，使用 this.$openDialog.deleteFile 调用创建文件夹服务
 * 在 *.js 文件中，需要先在文件顶部引入 Vue ，即 import Vue from 'vue' ，然后使用 Vue.property.$openDialog.deleteFile 调用创建文件夹服务
 * @description 各个服务的参数传递查看服务封装对应目录下的 index.js 文件，里面注明了需要传递的参数
 */

/**
 * 动态引入操作文件的弹窗组件
 */
const fileOperateDialog = require.context(
	'_c/file/dialog',
	true,
	/\.\/(.*)\/index.js$/
)
const openDialog = fileOperateDialog
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: fileOperateDialog(key).default }
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})
const fileOperateDialog2 = require.context(
	'_c/file2/dialog',
	true,
	/\.\/(.*)\/index.js$/
)
const openDialog2 = fileOperateDialog2
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: fileOperateDialog2(key).default }
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})

/**
 * 动态添加操作文件的遮罩或浮层组件
 */
const fileOperateBox = require.context(
	'_c/file/box',
	true,
	/\.\/(.*)\/index.js$/
)
const openBox = fileOperateBox
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: fileOperateBox(key).default }
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})
const fileOperateBox2 = require.context(
	'_c/file2/box',
	true,
	/\.\/(.*)\/index.js$/
)
const openBox2 = fileOperateBox2
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: fileOperateBox2(key).default }
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})

/**
 * 动态添加导航菜单的弹窗组件
 */
const header = require.context(
	'_c/header/dialog',
	true,
	/\.\/(.*)\/index.js$/
)
const headerDialog = header
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: header(key).default }
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})

/**
* 动态添加医学编码的弹窗组件
*/
const mcode = require.context(
	'_c/medicode/dialog',
	true,
	/\.\/(.*)\/index.js$/
)
const medicodeDialog = mcode
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: mcode(key).default }
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})

/**
* 动态添加报价管理的弹窗组件
*/
const ccode = require.context(
	'_c/crm/dialog',
	true,
	/\.\/(.*)\/index.js$/
)
const crmDialog = ccode
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: ccode(key).default }
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})

/**
* 动态添加项目管理的弹窗组件
*/
const pproject = require.context('_c/project/dialog', true, /\.\/(.*)\/index.js$/) //修改1，模块列表
const projectDialog = pproject // 修改2，模块加载俩表
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: pproject(key).default } // 修改3，这个对象的value是调用第一步的加载模块函数
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})
/**
* 动态添加CTMS的弹窗组件
*/
const ctms = require.context('_c/ctms/dialog', true, /\.\/(.*)\/index.js$/) //修改1，模块列表
const ctmsDialog = ctms // 修改2，模块加载俩表
	.keys()
	.map((key) => {
		return { [key.split('/')[1]]: ctms(key).default } // 修改3，这个对象的value是调用第一步的加载模块函数
	})
	.reduce((pre, next) => {
		return { ...pre, ...next }
	}, {})
export default { openDialog, openDialog2, openBox, openBox2, headerDialog, medicodeDialog, crmDialog, projectDialog, ctmsDialog }
