<template>
	<div style="height: 100%" v-loading="loading">
		<div class="view">
			<div class="tabTitle">
				<span style="line-height: 40px"
					>{{ type_name }}：{{ plan_number }}</span
				>
				<div class="leftHeader">
					<el-button-group>
						<el-button type="primary" disabled v-if="btn">归档</el-button>
						<el-button type="primary" v-if="showSend && btn" @click="sendEmail"
							>发送</el-button
						>
						<el-button
							type="primary"
							v-if="showSend && btn"
							@click="sendEmailList"
							>发送记录</el-button
						>
						<el-button
							type="primary"
							icon="el-icon-upload"
							@click="handleConfirmationSave"
							:disabled="!view && template_type == 'MonitoringReport'"
							v-if="btn"
							>保存</el-button
						>
						<el-button
							type="primary"
							icon="el-icon-search"
							@click="handleViewPDF"
							v-loading="handleViewPDFBtn"
							v-if="template_type == 'MonitoringReport'"
							>预览</el-button
						>
						<el-dropdown
							@command="handleSBaogao"
							v-loading="baogaoBtn"
							v-if="btn"
						>
							<el-button type="primary">
								生成报告<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									command="word"
									v-if="template_type != 'MonitoringReport'"
									>WORD版本</el-dropdown-item
								>
								<el-dropdown-item command="pdf">PDF版本</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-dropdown @command="handleCommand" v-if="btn">
							<el-button type="primary">
								下载报告<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									command="word"
									v-if="template_type != 'MonitoringReport'"
									>下载WORD</el-dropdown-item
								>
								<el-dropdown-item command="pdf">下载PDF</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</el-button-group>
				</div>
			</div>
			<div class="tab">
				<div class="left">
					<el-tabs tab-position="left" v-model="selectForm">
						<el-tab-pane
							v-for="t in templateData.form"
							:key="t.name"
							:name="t.name"
						>
							<div slot="label" class="linkDiv">
								<a class="linkInner" :title="t.title">{{ t.title }}</a>
								<span class="linkTip" v-if="t.annIndex > 0"
									>({{ t.annIndex }})</span
								>
							</div>
							<el-form :ref="'form' + t.name" :model="t" label-position="top">
								<el-form-item
									v-for="(item, index) in t.form"
									:key="index"
									:prop="'form.' + index + '.value'"
									:rules="{
										required: item.required,
										message:
											item.placeholder == '' ? '必填项' : item.placeholder,
										trigger: item.trigger
									}"
								>
									<template #label>
										{{ item.label }}
										<el-button
											v-if="
												template_type == 'MonitoringReport' &&
												item.type != 'title'
											"
											type="text"
											style="float: right"
											@click="pizhuBtn(item)"
											>批注</el-button
										>
									</template>
									<el-date-picker
										class="fullwidth"
										v-model="item.value"
										type="date"
										:placeholder="item.placeholder"
										format="yyyy 年 MM 月 dd 日"
										value-format="yyyy-MM-dd"
										v-if="item.type === 'date'"
										size="mini"
									>
									</el-date-picker>
									<el-input
										class="fullwidth"
										v-model="item.value"
										size="mini"
										v-if="item.type === 'input' || item.type === 'readonly'"
										:placeholder="item.placeholder"
										:disabled="item.type === 'readonly'"
									></el-input>
									<el-select
										class="fullwidth"
										v-if="item.type === 'select'"
										v-model="item.value"
										:placeholder="item.placeholder"
										size="mini"
									>
										<el-option
											v-for="(o, index) in item.option"
											:label="o.label"
											:value="o.value"
											:key="index"
										></el-option>
									</el-select>
									<el-time-picker
										class="fullwidth"
										size="mini"
										v-if="item.type === 'datetime'"
										v-model="item.value"
										:placeholder="item.placeholder"
										value-format="HH:mm:ss"
									>
									</el-time-picker>
									<el-radio-group
										size="mini"
										v-if="item.type === 'radio'"
										v-model="item.value"
										:placeholder="item.placeholder"
									>
										<el-radio
											v-for="(o, index) in item.option"
											:label="o.value"
											:key="index"
											>{{ o.label }}</el-radio
										>
									</el-radio-group>
									<el-checkbox-group
										size="mini"
										v-if="item.type === 'checkbox'"
										v-model="item.value"
										:placeholder="item.placeholder"
									>
										<el-checkbox
											v-for="(o, index) in item.option"
											:label="o.value"
											:key="index"
											>{{ o.label }}</el-checkbox
										>
									</el-checkbox-group>
									<el-input
										class="fullwidth"
										v-model="item.value"
										size="mini"
										v-if="item.type === 'textarea'"
										:placeholder="item.placeholder"
										type="textarea"
										:rows="3"
									></el-input>
									<span v-if="item.type === 'title'">
										{{ item.value }}
									</span>
									<!-- <span v-if="item.type === 'table'">{{ item }}</span> -->
									<el-table
										:data="item.value"
										border
										v-if="item.type === 'table'"
										:header-cell-style="headerCellStyle"
									>
										<el-table-column
											:label="item.label"
											align="center"
											v-for="(item, index) in item.option"
											:key="index"
										>
											<template slot-scope="scope">
												<el-input
													class="fullwidth"
													v-model="scope.row[`${item.value}`]"
													size="mini"
												></el-input>
											</template>
										</el-table-column>
										<el-table-column align="center" label="操作" width="100">
											<template slot-scope="scope">
												<div
													style="display: flex; justify-content: space-around"
												>
													<el-link type="primary" @click="add(scope.row, item)"
														>复制</el-link
													>
													<el-link type="primary" @click="del(scope.row, item)"
														>删除</el-link
													>
												</div>
											</template>
										</el-table-column>
									</el-table>
									<!-- <span>{{ rInfo.HospitalParticipants.rows }}</span> -->
									<el-table
										border
										v-if="
											item.type === 'HospitalParticipants' ||
											item.type === 'CRA' ||
											item.type === 'ICF' ||
											item.type === 'PD' ||
											item.type === 'SAE' ||
											item.type === 'QuestionSolve' ||
											item.type === 'QuestionUnresolved'
										"
										:data="item.value.rows"
										:header-cell-style="{ background: '#fafafa' }"
									>
										<el-table-column
											:label="item.label"
											align="center"
											v-for="(item, index) in item.value.columns"
											:key="index"
											:prop="item.value"
										>
										</el-table-column>
									</el-table>
									<el-descriptions
										:contentStyle="CS"
										:label-style="LS"
										class="margin-top"
										:column="2"
										direction="vertical"
										size="mini"
										border
										v-if="
											item.type === 'MonitoringInfo' ||
											item.type === 'Patient' ||
											item.type === 'SignatureInfo'
										"
									>
										<el-descriptions-item
											v-for="(i, index) in item.value"
											:key="index"
										>
											<template slot="label">
												{{ i.label }}
											</template>
											{{ i.value ? i.value : '---' }}
										</el-descriptions-item>
									</el-descriptions>
									<div class="annBackView" v-if="item.annObj.length > 0">
										<div class="itemTop-triangle"></div>
										<div class="ann" v-for="ann in item.annObj" :key="ann.id">
											<div class="itemTop">
												<div>
													<i class="el-icon-chat-dot-round"></i>&nbsp;
													<span>{{ ann.content }}</span>
												</div>
												<el-button
													type="text"
													style="float: right"
													@click="showhuifu(ann)"
													>回复
													<i
														class="el-icon-arrow-down"
														v-if="ann.children.length > 0 && !ann.show"
													></i>
													<i
														class="el-icon-arrow-up"
														v-if="ann.children.length > 0 && ann.show"
													></i
												></el-button>
											</div>
											<div class="itemBottom">
												<i class="el-icon-user"></i>&nbsp;
												<span>{{ ann.user_name }}</span
												>&nbsp; <i class="el-icon-time"></i>&nbsp;
												<span>{{ ann.createdAt | dateFormat }}</span>
											</div>
											<ann-com :ann="ann.children" v-if="ann.show"></ann-com>
											<div class="annCardText" v-if="ann.show">
												<el-input
													type="textarea"
													:rows="3"
													:placeholder="'回复' + ann.user_name"
													v-model="ann.reply"
												>
												</el-input>
												<div class="annCardBtn">
													<el-button size="mini" @click="ann.show = false"
														>取 消</el-button
													>
													<el-button
														size="mini"
														type="primary"
														@click="huifubtn(ann)"
														>回 复</el-button
													>
												</div>
											</div>
											<el-divider></el-divider>
										</div>
									</div>
									<div
										class="annBack"
										v-if="item.showAnn && template_type == 'MonitoringReport'"
									>
										<el-input
											type="textarea"
											:rows="3"
											placeholder="请输入批注"
											v-model="item.ann"
										>
										</el-input>
										<el-button
											type="text"
											icon="el-icon-check"
											class="annBtn"
											@click="annSave(item)"
										></el-button>
										<el-button
											type="text"
											icon="el-icon-close"
											class="annBtn"
											@click="annClose(item)"
										></el-button>
									</div>
									<div v-if="item.type === 'file'">
										<el-upload
											ref="upload"
											action="https://jsonplaceholder.typicode.com/posts/"
										>
											<el-button
												slot="trigger"
												size="small"
												type="primary"
												icon="el-icon-upload2"
												>附件上传</el-button
											>
											<el-button
												style="margin-left: 10px"
												size="small"
												type="primary"
												@click="fileDownLoad"
												icon="el-icon-download"
												>附件下载</el-button
											>
											<div slot="tip">
												{{ item.placeholder }}
											</div>
										</el-upload>
									</div>
								</el-form-item>
							</el-form>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="right" v-if="template_type != 'MonitoringReport'">
					<el-tabs type="border-card">
						<el-tab-pane label="WORD版本" class="progress">
							<div v-if="file_path" ref="output" class="word"></div>
							<el-empty v-else description="暂无数据"></el-empty>
						</el-tab-pane>
						<el-tab-pane label="PDF版本" class="progress">
							<div v-if="progressDiv && pdf_path" class="progressDiv">
								<el-progress
									type="circle"
									:percentage="percentage"
								></el-progress>
								<br />
								<p>正在加载到本地以便预览。。。</p>
							</div>
							<iframe
								v-else-if="pdf_path"
								ref="iframe"
								:src="src"
								frameborder="0"
								style="
									width: 100%;
									height: 100%;
									z-index: 7777;
									position: absolute;
									top: 0;
								"
							></iframe>
							<el-empty v-else description="暂无数据"></el-empty>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
  reportSave,
  createReport,
  createReportPDF,
  reportDetail,
  reportDownload,
  reportTemplatedetail,
  reportInfo,
  annotationsSave,
  annotationsList,
} from '_s/project';
import axios from 'axios'
import store from '@/store'
import annCom from './annCom.vue';
export default {
  components: {
    annCom
  },
  props: ['type_name', 'plan_number', 'row', 'template_type', 'showSend', 'view', 'btn'],
  data () {
    return {
      baogaoLoading: false,
      selectForm: '0',
      templateData: {
        form: []
      },
      baogaoBtn: false,
      percentage: 0,
      pdf: '',
      pdFilName: '',
      file_path: '',
      pdf_path: '',
      pdf_name: '',
      src: '',
      progressDiv: true,
      rInfo: {},
      handleViewPDFBtn: false,
      annotationsValue: '',
      loading: false,
      CS: {
        'text-align': 'center',
        'man-width': '250px',
        'word-break': 'break-all',
        'font-size': '14px'
      },
      LS: {
        'color': '#909399',
        'text-align': 'center',
        'font-weight': '600',
        'height': '40px',
        'width': '50%',
        'word-break': 'keep-all',
        'font-size': '14px'
      },
      project_id: localStorage.project_id,
      hospital_id: localStorage.hospital_id,
      selectShowHuiFu: ''
    }
  },
  methods: {
    async handleConfirmationSave () {
      try {
        await reportSave({
          plan_id: this.row.plan_id
          , id: this.row.id
          , project_id: localStorage.project_id
          , hospital_id: this.row.hospital_id
          , form_content: JSON.stringify(this.templateData.form)
          , template_type: this.template_type
        })
      } catch (error) {
        this.$message.error({ message: '请检查必填项！' })
      }
    },
    handleCommand (command) {
      if (command === 'word') {
        if (!this.file_path) return this.$message.error({ message: '请先生成WORD版本' })
        this.$emit('download', this.row.plan_id, localStorage.project_id, this.row.hospital_id, 'doc', this.template_type)
      } else {
        if (!this.pdf_path) return this.$message.error({ message: '请先生成PDF版本' })
        this.downloadFile(new Blob([this.pdf]), this.pdFilName)
      }
    },
    async handleSBaogao (command) {
      this.baogaoBtn = true
      let resArr = []
      for (let i = 0; i < this.templateData.form.length; i++) {
        this.$refs['form' + i][0].validate(valid => {
          if (!valid) {
            resArr.push(this.templateData.form[i])
          }
        })
      }
      if (resArr.length > 0) {
        this.$message.error({ message: `请检查 ${resArr[0].title} 必填项！` })
        this.selectForm = resArr[0].name
        return this.baogaoBtn = false
      }
      if (command === 'word') {
        const data = await createReport({ id: this.row.id, plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id, template_type: this.template_type })
        if (data.code === 0) {
          const { data } = await reportDetail({ id: this.row.id, plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id, template_type: this.template_type })
          this.file_path = data.file_path
          this.$nextTick(() => {
            this.viewWord()
          })
        }
      } else {
        const data = await createReportPDF({ id: this.row.id, plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id, template_type: this.template_type })
        if (data.code === 0) {
          const { data } = await reportDetail({ id: this.row.id, plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id, template_type: this.template_type })
          this.pdf_path = data.pdf_path
          this.pdf_name = data.pdf_name
          this.$nextTick(() => {
            this.viewPDF()
          })
        }
      }
      this.baogaoBtn = false
    },
    async init () {
      this.loading = true
      const { data: rInfo } = await reportInfo({ id: this.row.id, plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id })
      this.rInfo = rInfo
      const { data: res } = await reportTemplatedetail({ project_id: localStorage.project_id, hospital_id: this.row.hospital_id, plan_id: this.row.plan_id, type: this.row.type, template_type: this.template_type })
      if (!res) return false
      const form = JSON.parse(res.template_form) ?? []
      const { data: annlist } = await annotationsList({ plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id })
      const { data } = await reportDetail({ id: this.row.id, plan_id: this.row.plan_id, project_id: localStorage.project_id, hospital_id: this.row.hospital_id, template_type: this.template_type })
      let payload = []
      if (data && data.form_content) payload = JSON.parse(data.form_content)
      for (const i of form) {
        let index = 0
        const p = payload.find(p => p.name == i.name)?.form
        for (const n of i.form) {
          if (p) n.value = p.find(c => c.name == n.name)?.value
          for (const a of annlist) {
            if (a.field_id === n.guid) {
              if (a.id === this.selectShowHuiFu) a.show = true
              n.annObj.push(a)
            }
          }
          if (n.annObj.length > 0) index += 1
          if (rInfo[`${n.type}`]) n.value = JSON.parse(JSON.stringify(rInfo[`${n.type}`]))
        }
        i.annIndex = index
      }
      this.templateData.form = form
      this.file_path = data?.file_path
      this.pdf_path = data?.pdf_path
      this.pdf_name = data?.pdf_name
      this.loading = false
      this.$nextTick(() => {
        if (this.template_type != 'MonitoringReport') {
          if (this.file_path) {
            this.viewWord()
          }
        }

        if (this.pdf_path) {
          this.viewPDF()
        }
      })

    },
    viewWord () {
      if (this.$refs.output) this.$refs.output.innerHTML = ''
      const { token } = store.state.user
      const iframe = document.createElement('iframe')
      iframe.src = `/api/v1/file/previewHtml?id=${this.row.plan_id}&token=${token.access_token}&mode=${this.template_type}`
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      iframe.style.border = 'none'
      this.$refs.output.appendChild(iframe)
    },
    viewPDF () {
      this.initHttp(this.row.plan_id, localStorage.project_id, this.row.hospital_id, 'pdf', this.template_type).then(res => {
        this.pdf = res.data
        this.pdFilName = res.fileName
        let blob = new Blob([this.pdf], { type: "application/pdf" });
        this.src = window.URL.createObjectURL(blob) + '#toolbar=0&view=FitH,top';
        this.progressDiv = false
      }).catch(error => {
        console.info(error)
      })
    },
    downloadFile (blob, fileName) {
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a")
        elink.download = fileName
        elink.style.display = "none"
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        setTimeout(function () {
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        }, 100)
      } else {
        navigator.msSaveBlob(blob, fileName)
      }
    },
    downClose () {
      this.source.cancel('log==客户手动取消下载')
      this.downloadVisible = false
    },
    initHttp (plan_id, project_id, hospital_id, file_type, template_type) {
      var _this = this
      //取消时的资源标记
      this.source = axios.CancelToken.source()
      return reportDownload(
        { plan_id: plan_id, project_id, hospital_id, file_type, template_type },
        function (ProgressEvent) {
          const load = ProgressEvent.loaded
          const total = ProgressEvent.total
          const progress = (load / total) * 100
          //一开始已经在计算了 这里要超过先前的计算才能继续往下
          if (progress > _this.percentage) {
            _this.percentage = Math.floor(progress)
          }
          if (progress == 100) {
            //下载完成
            _this.downloadVisible = false
          }
        },
        this.source.token,//声明一个取消请求token
      )
    },
    add (row, item) {
      // const hang = {}
      // for (const o of item.option) {
      //   hang[o.value] = ''
      // }
      item.value.push(JSON.parse(JSON.stringify(row)))
    },
    del (row, item) {
      if (item.value.length === 1) return this.$message.error('最少保留一个项目')
      item.value.splice(item.value.indexOf(row), 1)
    },
    sendEmail () {
      if (!this.pdf_name) {
        return this.$message.error({ message: '请先生成PDF' })
      }
      this.$emit('sendEmail', this.pdf_name, this.pdf_path)
    },
    sendEmailList () {
      this.$emit('sendEmailList')
    },
    headerCellStyle () {
      return 'background:#fafafa;'
    },
    pizhuBtn (row) {
      row.showAnn = true
    },
    async handleViewPDF () {
      if (!this.pdf) {
        return this.$message.error({ message: '请先生成PDF' })
      }
      console.log(this.pdf, 'viewForm.vue 675行');
      this.$emit('handleViewPDFVisible', window.URL.createObjectURL(this.pdf) + '#toolbar=0&view=FitH,top')
    },
    async huifubtn (row) {
      if (!row.reply) return this.$message.error({ message: '请输入回复内容！' })
      const data = await annotationsSave({
        reply_id: row.id,
        project_id: localStorage.project_id,
        hospital_id: this.row.hospital_id,
        plan_id: this.row.plan_id,
        field_id: row.field_id,
        content: row.reply
      })
      if (data.code === 0) {
        row.ann = ''
        row.showAnn = false
        this.selectShowHuiFu = row.id
        this.init()
      }
    },
    async annSave (row) {
      const data = await annotationsSave({
        reply_id: 0,
        project_id: localStorage.project_id,
        hospital_id: this.row.hospital_id,
        plan_id: this.row.plan_id,
        field_id: row.guid,
        content: row.ann
      })
      if (data.code === 0) {
        row.ann = ''
        row.showAnn = false
        this.init()
      }
    },
    annClose (row) {
      row.ann = ''
      row.showAnn = false
    },
    fileDownLoad () {

    },
    showhuifu (row) {
      row.show = !row.show
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.view {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.tabTitle {
	padding: 0px 0px 10px;
	border-bottom: 1px solid rgb(239, 239, 239);
	display: flex;
	justify-content: space-between;
	border-bottom: solid 1px #dcdfe6;
}
.tab {
	display: flex;
	height: 100%;
	.left {
		flex: 1;
		::v-deep .el-tabs__item {
			width: 200px;
			display: grid !important;
			justify-content: right;
		}
	}
	.right {
		width: 50%;
		border-left: solid 1px #dcdfe6;
		padding: 10px 10px 1px 10px;
		::v-deep .el-tabs__content {
			padding: 0px;
		}
	}
}
.fullwidth {
	width: 100%;
}
.el-form {
	padding-top: 15px;
	padding-right: 15px;
}
.word {
	height: 100%;
	width: 100%;
}
.el-tabs {
	height: 100%;
	.el-tab-pane {
		overflow: auto;
		height: 100%;
	}
}
.progress {
	display: flex;
	align-items: center;
	justify-content: center;
	.progressDiv {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}
::v-deep .el-form-item__label {
	padding: 0px;
}

.linkDiv {
	display: flex;
	width: 180px;
	justify-content: flex-end;
}
.linkInner {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
}
.linkTip {
	color: red;
	padding-left: 5px;
}
.el-form-item {
	padding-left: 15px;
}
::v-deep .el-form-item__label {
	width: 100%;
}
// .formItem {
// 	display: flex;
// 	justify-content: space-between;
// }
.annBack {
	background-color: #fff3f3;
	padding: 20px;
	display: flex;
	.annBtn {
		font-size: 30px;
		margin-left: 10px;
	}
}
.annBackView {
	background-color: #fff3f3;
	position: relative;
	padding: 10px;
	margin-top: 10px;
	.itemTop-triangle {
		position: absolute;
		top: -8px;
		left: 30px;
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-bottom: 9px solid #fff3f3;
	}
	.itemTop {
		color: rgb(250, 173, 20);
		display: flex;
		justify-content: space-between;
	}
	.el-divider {
		margin: 10px 0px;
	}
}
</style>