/**
 * 存放项目配置项
 * @author 孙道林
 */
export default {
  /**
    * 钉钉扫码登录跳转地址
    */
  dingdingUrl: window.location.href,
  /**
    * 钉钉扫码登录发送静态消息，可空
    */
  dingdingState: 'stemexcel',
  /**
    * 请求后台接口 URL 代理时的上下文
    * @description 开发环境：修改 .env.development 中对应的值
    * 生产环境：修改 .env.production 中对应的值
 */
  baseContext: `/api`,
  // baseContextStrg: `/api/strg`,
  baseURL: '',
  /**
    * 存放 token 时的变量名
 */
  tokenKeyName: 'token',
}
