import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/user' //  用户模块
import fileList from './module/fileList' //  文件列表模块
import { allColumnList } from '@/libs/map.js'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    // 文件查看模式
    fileModel: (state) => state.fileList.fileModel === null ? 0 : Number(state.fileList.fileModel),
    // 表格显示列
    selectedColumnList: (state) => {
      return state.fileList.selectedColumnList === null
        ? allColumnList
        : state.fileList.selectedColumnList.split(',')
    },
    // 网格模式 文件图标大小
    gridSize: (state) => state.fileList.gridSize,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    fileList
  }
})
