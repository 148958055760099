<template>
	<div class="pdf">
		<!-- <div
			ref="mkdiv"
			style="
				width: 98.5%;
				height: 100%;
				z-index: 8888;
				position: absolute;
				top: 0;
			"
		></div> -->
		<iframe
			ref="iframe"
			:src="src"
			frameborder="0"
			style="
				width: 100%;
				height: 100%;
				z-index: 7777;
				position: absolute;
				top: 0;
			"
		></iframe>
	</div>
</template>

<script>
// import store from '@/store'
// import watermark from '_l/watermark.js';
export default {
  name: "PdfView",
  props: {
    data: ArrayBuffer,
  },
  data () {
    return {
      src: ''
    }
  },
  created () {
    let blob = new Blob([this.data], { type: "application/pdf" });
    this.src = window.URL.createObjectURL(blob) + '#toolbar=0&view=FitH,top';
    //const { name, email } = store.state.user.userInfo
    // this.$nextTick(() => {
    //   watermark.set(name + email, this.$refs.mkdiv)
    // })
  }
}
</script>
<style lang="scss">
.pdf {
	position: relative;
	height: calc(100vh - 43px);
}
</style>