import Vue from 'vue'
// 导入组件
import ChoiceUserDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个“子类”
const ChoiceUserOrFolderConstructor = Vue.extend(ChoiceUserDialog)

let choiceUserOrFolderInstance = null
/**
 * 用户组绑定人员或者文件夹
 * @param {string} title 用户组名称
 * @param {number} type  1--绑定用户，0--绑定文件夹
 * @param {number} groupId  用户组id
 */
const initInstanceChoiceUserOrFolder = (title, type, groupId) => {
  choiceUserOrFolderInstance = new ChoiceUserOrFolderConstructor({
    el: document.createElement('div'),
    data () {
      return {
        title, type, groupId
      }
    }
  })
}
/**
 * 用户组信息实例 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
const choiceUserOrFolderDialog = ({ title, type, groupId }) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (choiceUserOrFolderInstance !== null) {
    document.body.removeChild(choiceUserOrFolderInstance.$el)
  }
  return new Promise((reslove) => {
    initInstanceChoiceUserOrFolder(title, type, groupId)
    choiceUserOrFolderInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res === 'cancel' && choiceUserOrFolderInstance !== null) {
        document.body.removeChild(choiceUserOrFolderInstance.$el)
        choiceUserOrFolderInstance = null
      }
    }
    document.body.appendChild(choiceUserOrFolderInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      choiceUserOrFolderInstance.visible = true //  打开对话框
    })
  })
}

export default choiceUserOrFolderDialog
