export default {
  path: '/medicode',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/medicode/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'rule',
      component: () => import(/* webpackChunkName: 'rule' */'@/views/medicode/index')
    },
    {
      path: '/medicode/meddra/dictionary',
      name: 'meddra_dictionary',
      component: () => import(/* webpackChunkName: 'meddra_dictionary' */'@/views/medicode/meddra/dictionary/index')
    },
    {
      path: '/medicode/meddra/codefile',
      name: 'meddra_codefile',
      component: () => import(/* webpackChunkName: 'meddra_codefile' */'@/views/medicode/meddra/codefile/index'),
    },
    {
      path: '/medicode/meddra/codefile/editcode',
      name: 'editCodefileByMeddra',
      component: () => import(/* webpackChunkName: 'meddra_codefile_editcode' */'@/views/medicode/meddra/codefile/editcode/index')
    },
    {
      path: '/medicode/meddra/codelibrary',
      name: 'meddra_codelibrary',
      component: () => import(/* webpackChunkName: 'meddra_codelibrary' */'@/views/medicode/meddra/codelibrary/index')
    },
    {
      path: '/medicode/whodd/dictionary',
      name: 'whodd_dictionary',
      component: () => import(/* webpackChunkName: 'whodd_dictionary' */'@/views/medicode/whodd/dictionary/index')
    },
    {
      path: '/medicode/whodd/codefile',
      name: 'whodd_codefile',
      component: () => import(/* webpackChunkName: 'whodd_codefile' */'@/views/medicode/whodd/codefile/index')
    },
    {
      path: '/medicode/whodd/codefile/editcode',
      name: 'editCodefileByWhodd',
      component: () => import(/* webpackChunkName: 'editCodefileByWhodd' */'@/views/medicode/whodd/codefile/editcode/index')
    },
    {
      path: '/medicode/whodd/dosageform',
      name: 'whodd_dosageform',
      component: () => import(/* webpackChunkName: 'whodd_dosageform' */'@/views/medicode/whodd/dosageform/index')
    }
  ]
}