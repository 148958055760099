export default {
  path: '/meetingRoom',
  component: () => import(/* webpackChunkName: 'meetingRoomlayout' */'@/views/meetingRoom/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'meetingRoom',
      component: () => import(/* webpackChunkName: 'meetingRoom' */'@/views/meetingRoom/index')
    },
    {
      path: '/meetingRoom/approval',
      name: 'meetingRoomApproval',
      component: () => import(/* webpackChunkName: 'meetingRoomApproval' */'@/views/meetingRoom/approval')
    },
    {
      path: '/meetingRoom/roomSettings',
      name: 'roomSettings',
      component: () => import(/* webpackChunkName: 'roomSettings' */'@/views/meetingRoom/roomSettings')
    }
  ]
}