<template>
	<div>
		<div v-for="annItem in ann" :key="annItem.id">
			<div class="itemBottom">
				<span>{{ annItem.user_name }}</span
				>&nbsp;
				<span>{{ annItem.createdAt | dateFormat }}</span>
			</div>
			<div class="itemTop">
				<div>
					<span>回复：{{ annItem.content }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'annCom',
  props: ['ann']
}
</script>

<style lang="scss" scoped>
.annBtn {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.annCardBtn {
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;
}
</style>