<template>
  <TablePage>
    <template #header>
      <div class="pageHeader">
        <p>业务机会列表</p>
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="业务机会名称" prop="lltCode">
            <el-input v-model="form.name" placeholder="输入业务机会名称"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="display:flex;">
              <el-button type="primary" @click="onSubmit" :loading="isloading">查询</el-button>
              <el-button type="default" @click="resetQuery">重置</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #table>
      <el-table :data="tableData" height="100%" v-loading="isloading" :highlight-current-row="true"
        @row-click="singleElection">
        <el-table-column align="center" width="55" label="选择">
          <template slot-scope="scope">
            <el-radio @click.native.prevent="return false" class="radio" v-model="code"
              :label="scope.row.code">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="业务机会编码" width="120">
        </el-table-column>
        <el-table-column prop="name" label="业务机会名称">
        </el-table-column>
        <el-table-column prop="company" label="客户名称">
        </el-table-column>
        <el-table-column prop="product" label="产品名称">
        </el-table-column>
        <el-table-column prop="type" label="类型" width="100">
        </el-table-column>
        <el-table-column prop="territory" label="领域" width="50">
        </el-table-column>
        <el-table-column prop="patient_type" label="受试者类型" width="100">
        </el-table-column>
        <el-table-column prop="indication" label="适应症">
        </el-table-column>
        <el-table-column prop="user_name" label="业务机会所有人" width="150">
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      <div class="footer">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
          :page-sizes="[20, 100, 300, 500]" :page-size="form.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total" :disabled="isloading">
        </el-pagination>
      </div>
    </template>
  </TablePage>
</template>

<script>
import TablePage from '_c/tablePage'
import { getBusinessList } from '_s/crm';
export default {
  components: {
    TablePage
  },
  data () {
    return {
      tableData: [],
      total: 0,
      code: '',
      isloading: false,
      form: {
        page: 1,
        pageSize: 20,
        name: ''
      }
    }
  },
  methods: {
    async initTable () {
      this.isloading = true
      const { data } = await getBusinessList(this.form)
      this.tableData = data.data
      this.total = data.total
      this.isloading = false
    },
    handleSizeChange (val) {
      this.form.pageSize = val
      this.initTable()
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.initTable()
    },
    onSubmit () {
      this.form.page = 1
      this.initTable()
    },
    resetQuery () {
      this.form.page = 1
      this.form.pageSize = 20
      this.form.name = ''
      this.initTable()
    },
    singleElection (row) {
      this.code = row.code
      this.$emit('changeColum', row)
    }
  },
  created () {
    this.initTable()
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0 0;
}

.pageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.demo-form-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-form-item {
  margin-bottom: 0px;
}
</style>