import { get, post, postProgress } from './http'
import qs from 'qs';

// MedDRA医学辞典
export const getMedDRA = data => get(`/v1/mediCode/medDRA?${qs.stringify(data)}`)

// MedDRA医学辞典配置信息
export const getMedDRASetInfo = () => get(`/v1/mediCode/medDRA/setInfo`)

// MedDRA编码库
export const getMedDRALibrary = data => get(`/v1/mediCode/medDRA/library?${qs.stringify(data)}`)

// WhoDD医学辞典
export const getWhoDD = data => get(`/v1/mediCode/whoDD?${qs.stringify(data)}`)

// WhoDD医学辞典配置信息
export const getWhoDDSetInfo = () => get(`/v1/mediCode/whoDD/setInfo`)

// MedDRA医学编码
export const getMedDRACode = data => get(`/v1/mediCode/medDRA/code?${qs.stringify(data)}`)

// 新建 - MedDRA医学编码
export const medDRAAddCode = data => post(`/v1/mediCode/medDRA/newCode`, data, true)

// 删除 - MedDRA医学编码
export const medDRADeleteCode = data => post(`/v1/mediCode/medDRA/deleteCode`, data)

// 导出 - MedDRA医学编码
export const medDRAExportCode = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/mediCode/medDRA/exportCode`, data, onDownloadProgress, cancelToken, true)

// 获取编码数据 - MedDRA医学编码
export const getMedDRAData = data => get(`/v1/mediCode/medDRA/medDRAData?${qs.stringify(data)}`)

// 编码数据更新 - MedDRA医学编码
export const updateMedDRAData = data => post(`/v1/mediCode/medDRA/medDRADataUpdate`, data)

// 删除 - MedDRA编码库
export const deleteLibrary = data => post(`/v1/mediCode/medDRA/deleteLibrary`, data)

// 删除 - MedDRA编码库
export const medDRAAddLibrary = data => post(`/v1/mediCode/medDRA/newLibrary`, data)

// WhoDD医学编码
export const getWhoDDCode = data => get(`/v1/mediCode/whoDD/code?${qs.stringify(data)}`)

// 新建 - WhoDD医学编码
export const whoDDAddCode = data => post(`/v1/mediCode/whoDD/newCode`, data, true)

// 删除 - WhoDD医学编码
export const whoDDDeleteCode = data => post(`/v1/mediCode/whoDD/deleteCode`, data)

// 导出 - MedDRA医学编码
export const whoDDExportCode = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/mediCode/whoDD/exportCode`, data, onDownloadProgress, cancelToken, true)

// WhoDD标准化库
export const getWhoDDForm = data => get(`/v1/mediCode/whoDD/form?${qs.stringify(data)}`)

// 新建 - WhoDD标准化库
export const WhoDDAddForm = data => post(`/v1/mediCode/whoDD/newForm`, data)

// 删除 - WhoDD标准化库
export const WhoDDDeletForm = data => post(`/v1/mediCode/whoDD/deleteForm`, data)

// 获取编码数据 - WhoDD医学编码 
export const getWhoDDData = data => get(`/v1/mediCode/whoDD/whoDDData?${qs.stringify(data)}`)

// 获取ATC编码数据 - WhoDD医学编码 
export const getWhoDDATCData = data => get(`/v1/mediCode/whoDD/whoDDATC?${qs.stringify(data)}`)

// 编码数据更新 - WhoDD医学编码 
export const updateWhoDDData = data => post(`/v1/mediCode/whoDD/whoDDDataUpdate`, data)

