<template>
  <el-dialog :visible.sync="visible" fullscreen :modal="false" :destroy-on-close="true" :show-close="false" center>
    <businessList @changeColum="changeColum"></businessList>
    <span slot="footer" class="dialog-footer">
      <el-button class="btn" @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">提 交 选 择</el-button>
    </span>
  </el-dialog>
</template>

<script>
import businessList from '_c/crm/businessList.vue'
export default {
  components: {
    businessList
  },
  data () {
    return {
      visible: false,
      value: ''
    }
  },
  methods: {
    handleSubmit () {
      if (this.value) {
        this.visible = false
        this.callback({ done: true, row: this.value })
      } else {
        this.$message.error({ message: '请选择业务机会' })
      }
    },
    changeColum (value) {
      this.value = value
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 5px;
}

::v-deep .el-dialog__body {
  height: 94%;
}

::v-deep .el-dialog__footer {
  padding: 0;
}

.content {
  display: flex;
  height: 100%;
}
</style>