export default {
  path: '/hospital',
  component: () => import(/* webpackChunkName: 'hospital' */'@/views/hospital/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'hospital',
      component: () => import(/* webpackChunkName: 'hospitalIndex' */'@/views/hospital/index')
    },
    {
      path: '/hospital/hospitalInfo',
      name: 'hospitalInfo',
      component: () => import(/* webpackChunkName: 'hospitalInfo' */'@/views/hospital/hospitalInfo')
    },
  ]
}