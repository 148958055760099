<template>
  <el-dialog title="退出提示" :visible.sync="visible" width="525px" center :close-on-click-modal="false">
    <span style="font-size: 16px;"><i class="el-icon-warning" style="color:#e6a23c;"></i> 确认退出吗？</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button v-if="type" type="primary" @click="handleRedirect">返回我的工作台</el-button>
      <el-button type="danger" @click="handleLogout">退 出</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { logout } from '_s/user';
import store from '@/store'
import router from '@/router'
export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    async handleLogout () {
      await logout()
      // 退出系统，清空保存的用户信息token
      store.commit('setToken', null)
      store.commit('setUserInfo', null)
      location.reload()
    },
    handleRedirect () {
      this.handleClose()
      router.push({ path: '/' })
    },
    handleClose () {
      this.visible = false
    }
  }
}
</script>