<template>
	<!-- 用户组信息对话框 -->
	<el-dialog
		title="权限组信息"
		:visible.sync="visible"
		:close-on-click-modal="false"
		width="550px"
		@open="handleDialogOpen"
		@close="handleDialogClose"
	>
		<el-form
			class="rename-file-form"
			:model="form"
			:rules="formRules"
			ref="form"
			label-width="100px"
			label-position="top"
		>
			<el-form-item label="权限组名称" prop="name">
				<el-input
					v-model="form.name"
					placeholder="请输入权限组名称"
					type="text"
					maxlength="50"
					show-word-limit
				></el-input>
			</el-form-item>
			<el-form-item label="是否启用">
				<el-switch
					v-model="form.status"
					active-color="#13ce66"
					inactive-color="#ff4949"
					active-value="0"
					inactive-value="1"
				>
					></el-switch
				>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleDialogClose">取 消</el-button>
			<el-button
				type="primary"
				:loading="sureBtnLoading"
				@click="handleDialogSure()"
				>确 定</el-button
			>
		</div>
	</el-dialog>
</template>

<script>
import { newUserGroup, changeUserGroup } from '_s/fileStorage.js';
export default {
  data () {
    const validateFileName = (rule, value, callback) => {
      const fileNameReg = new RegExp(`[\\\\/:*?"<>|]`)
      if (value && fileNameReg.test(value)) {
        callback(new Error(`名称不能包含下列任何字符：\\/:*?"<>|`))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      sureBtnLoading: false,
      form: {
        name: '',
        groupId: 0,
        status: '0'
      },
      formRules: {
        name: [
          { required: true, message: '请输入文件夹名称', trigger: 'blur' },
          { validator: validateFileName, trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    async handleDialogSure () {
      try {
        await this.$refs['form'].validate()
        this.sureBtnLoading = true
        let data = {}
        if (this.type === 0) {
          data = await changeUserGroup(this.form)
        } else {
          data = await newUserGroup(this.form)
        }
        if (data.code === 0) {
          this.visible = false
        }
        this.callback({ done: data.code === 0 })
        this.sureBtnLoading = false
      } catch (error) {
        console.log(error)
      }
    },
    async handleDialogOpen () {
      this.form.name = this.name
      if (this.type === 0) {
        this.form.groupId = this.groupId
        this.form.status = this.status + ''
      }
    },
    handleDialogClose () {
      this.$refs['form'].resetFields()
      this.visible = false
      this.callback({ done: false })
    }
  }
}
</script>

<style lang="scss" scoped></style>