<template>
	<!-- 预览文件弹窗 -->
	<el-drawer
		:title="fileInfo.fileName"
		:visible.sync="visible"
		size="100%"
		direction="rtl"
		:destroy-on-close="true"
		:before-close="handleClose"
		@opened="opened"
	>
		<div ref="mkdiv" class="mkdiv"></div>
		<div v-if="progressDiv" class="content">
			<el-progress type="circle" :percentage="percentage"></el-progress>
			<br />
			<p>正在加载到本地以便预览。。。</p>
		</div>
		<div style="height: 100%" id="output" ref="output"></div>
	</el-drawer>
</template>

<script>
import { downloadFile, previewType } from '_s/fileStorage.js'
import { readBuffer, render } from '_p/media'
import axios from 'axios'
import store from '@/store'
import watermark from '_l/watermark.js';

export default {
  name: 'DownLoadFileDialog',
  data () {
    return {
      visible: false, // 抽屉是否可见
      editor: {},
      progressDiv: true,
      percentage: 0,  // 进度条的百分比
      source: null,    // 取消下载时的资源对象
    }
  },
  methods: {
    async initPreview () {                                                    // 初始化预览，判断需要预览的文件类型，分别处理
      const { data } = await previewType({ id: this.fileInfo.id })            // 获得需要预览的文件数据
      const extend = data.document_type;                                      // 取得扩展名
      const output = this.$refs.output                                        // 包裹的容器
      if (['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'mp4', 'pdf'].includes(data.document_type)) {
        // 预览office和mp4
        this.progressDiv = false
        const { token } = store.state.user
        const iframe = document.createElement('iframe')
        iframe.src = `/api/v1/file/previewHtml?id=${this.fileInfo.id}&token=${token.access_token}&mode=Storage`
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        iframe.style.border = 'none'
        this.$refs.output.appendChild(iframe)
      } else {
        // 统一预览  pdf,图片,等各种代码文件
        this.preview(extend, output)
      }
    },
    handleClose () { //手动关闭抽屉
      this.visible = false
      if (this.source) this.source.cancel('log==客户手动取消下载')
    },
    download () {
      //取消时的资源标记
      this.source = axios.CancelToken.source()
      return downloadFile(
        { id: this.fileInfo.id, preview: 1 },
        ProgressEvent => {
          const load = ProgressEvent.loaded
          const total = ProgressEvent.total
          const progress = (load / total) * 100
          if (progress > this.percentage) {
            this.percentage = Math.floor(progress)
          }
        },
        this.source.token,  //声明一个取消请求token
      )
    },
    // 统一预览逻辑，在 plugins/media 里
    async preview (extend, output) {
      const res = await this.download()
      this.progressDiv = false
      const file = new File([res.data], this.fileInfo.fileName, {})
      const arrayBuffer = await readBuffer(file)
      await render(arrayBuffer, extend, output)
    },
    opened () {
      const { name, email } = store.state.user.userInfo
      this.$nextTick(() => {
        watermark.set(name + email, this.$refs.mkdiv)
      })
    }
  },
  created () {
    this.initPreview()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__header {
	padding: 10px;
	margin-bottom: 0;
}

::v-deep .el-drawer__body {
	overflow: hidden;
}

.content {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.well {
	height: 100%;
	width: 100%;
}
.mkdiv {
	margin: 0 auto;
	margin-top: 75px;
	width: 80%;
	height: calc(100% - 135px);
	z-index: 8888;
	position: absolute !important;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}
</style>