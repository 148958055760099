import Vue from 'vue'
// 导入组件
import AddOrEditFolderDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个“子类”
const AddOrEditFolderConstructor = Vue.extend(AddOrEditFolderDialog)

let addOrEditFolderInstance = null
/**
 * 初始化新建文件夹实例
 * @param {number} folderId 文件夹id
 * @param {number} parentId 父文件夹ID
 * @param {string} name 文件夹名字
 * @param {number} type 1-新建，0-修改
 * @param {string} parentName 父文件夹名字
 * @param {number} order 排序字段
 */
const initInstanceAddFolder = (folderId, parentId, type, name, parentName, order, showCascader) => {
	addOrEditFolderInstance = new AddOrEditFolderConstructor({
		el: document.createElement('div'),
		data () {
			return {
				folderId, parentId, type, name, parentName, order, showCascader
			}
		}
	})
}
/**
 * 新建文件夹 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
const showAddOrEditFolderDialog = ({ folderId, parentId, type, name, parentName, order, showCascader }) => {
	// 非首次调用服务时，在 DOM 中移除上个实例
	if (addOrEditFolderInstance !== null) {
		document.body.removeChild(addOrEditFolderInstance.$el)
	}
	return new Promise((reslove) => {
		initInstanceAddFolder(folderId, parentId, type, name, parentName, order, showCascader)
		addOrEditFolderInstance.callback = (res) => {
			reslove(res)
			// 服务取消时卸载 DOM
			if (res === 'cancel' && addOrEditFolderInstance !== null) {
				document.body.removeChild(addOrEditFolderInstance.$el)
				addOrEditFolderInstance = null
			}
		}
		document.body.appendChild(addOrEditFolderInstance.$el) //  挂载 DOM
		Vue.nextTick(() => {
			addOrEditFolderInstance.visible = true //  打开对话框
		})
	})
}

export default showAddOrEditFolderDialog
