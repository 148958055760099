import Vue from 'vue'
import MeddraEditCodeDialog from './Dialog.vue'
const MeddraEditCodeConstructor = Vue.extend(MeddraEditCodeDialog)

let meddraEditCodeInstance = null
export default (obj, from) => {
  if (meddraEditCodeInstance !== null) {
    document.body.removeChild(meddraEditCodeInstance.$el)
  }
  // id  编码编号
  // taskId 序号
  // language 语言
  // version 版本
  // name 编码名称
  // otherName 编码其他名称
  // from 从哪个页面调用这个弹出层
  let { id, taskId, language, version, name, otherName } = obj
  return new Promise((reslove) => {
    meddraEditCodeInstance = new MeddraEditCodeConstructor({
      el: document.createElement('div'),
      data () {
        return {
          id, taskId, language, version, name, otherName, from
        }
      }
    })
    meddraEditCodeInstance.callback = (res) => {
      reslove(res)
      if (res.done && meddraEditCodeInstance !== null) {
        document.body.removeChild(meddraEditCodeInstance.$el)
        meddraEditCodeInstance = null
      }
    }
    document.body.appendChild(meddraEditCodeInstance.$el)
    Vue.nextTick(() => {
      meddraEditCodeInstance.visible = true
    })
  })
}
