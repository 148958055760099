<template>
	<el-dialog
		title="新建"
		:visible.sync="visible"
		fullscreen
		center
		:destroy-on-close="true"
	>
		<el-form
			:model="ruleForm"
			:rules="rules"
			ref="ruleForm"
			label-width="100px"
			class="demo-ruleForm"
		>
			<el-form-item label="编码标题" prop="title">
				<el-input v-model="ruleForm.title"></el-input>
			</el-form-item>
			<el-form-item label="语言" prop="language">
				<el-select
					style="width: 100%"
					v-model="ruleForm.language"
					placeholder="请选择语言"
				>
					<el-option
						v-for="item in langList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="版本" prop="version">
				<el-select
					style="width: 100%"
					v-model="ruleForm.version"
					placeholder="请选择版本"
				>
					<el-option
						v-for="item in versionList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				v-if="this.type === 'meddra'"
				label="版本升级"
				prop="upgrade"
			>
				<el-switch
					v-model="ruleForm.upgrade"
					active-value="1"
					inactive-value="0"
				></el-switch>
			</el-form-item>
			<el-form-item label="编码文件" prop="sourceFile">
				<el-upload
					class="upload-demo"
					drag
					:auto-upload="false"
					accept=".xlsx, .xls"
					:limit="1"
					:on-exceed="handleExceed"
					:on-change="handleSourceChange"
					action="#"
					:on-remove="handleRemoveSource"
					:file-list="sourceList"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">
						只能上传xlsx/xls文件，且不超过10MB
					</div>
				</el-upload>
			</el-form-item>
			<el-form-item
				v-if="this.type === 'meddra'"
				label="对比文件"
				prop="contrastFile"
			>
				<el-upload
					class="upload-demo"
					drag
					:auto-upload="false"
					accept=".xlsx, .xls"
					:limit="1"
					:on-exceed="handleExceed"
					:on-change="handleContrastChange"
					action="#"
					:on-remove="handleRemoveContrast"
					:file-list="contrastList"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">
						只能上传xlsx/xls文件，且不超过10MB
					</div>
				</el-upload>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="primary" @click="handleSubmit" :loading="isloading"
				>提 交</el-button
			>
		</div>
	</el-dialog>
</template>

<script>
import { getMedDRASetInfo, getWhoDDSetInfo, medDRAAddCode, whoDDAddCode } from '_s/medicode.js';
export default {
  data () {
    return {
      visible: false,
      langList: [],
      versionList: [],
      langObj: {},
      ruleForm: { title: '', language: '', version: '', sourceFile: '', contrastFile: '' },
      rules: {
        title: [{ required: true, message: '请输入编码标题', trigger: 'blur' }],
        language: [{ required: true, message: '请选择语言', trigger: 'change' }],
        version: [{ required: true, message: '请选择版本', trigger: 'change' }],
        sourceFile: [{ required: true, message: '请至少选择一个编码文件', trigger: 'change' }]
      },
      isloading: false,
      sourceList: [],
      contrastList: []
    }
  },
  methods: {
    async loadSetInfo () {
      let res = {}
      if (this.type === 'meddra') res = await getMedDRASetInfo()
      if (this.type === 'whodd') res = await getWhoDDSetInfo()
      this.langObj = res.data.language
      for (let i in this.langObj) {
        this.langList.push({ label: this.langObj[i], value: i })
      }
      const v = res.data.version
      for (let i in v) {
        this.versionList.push({ label: v[i], value: i })
      }
    },
    handleExceed () {
      this.$message.warning(`当前最多只能选择 1 个文件，请点击文件名后的 X ，删除后再试！`)
    },
    handleSourceChange (file) {
      if (file.size / 1024 / 1024 > 10) {
        this.$message.error("上传文件大小不能超过 10MB!")
        this.sourceList = []
        return false
      }
      this.ruleForm.sourceFile = file
    },
    handleContrastChange (file) {
      if (file.size / 1024 / 1024 > 10) {
        this.$message.error("上传文件大小不能超过 10MB!")
        this.contrastList = []
        return false
      }
      this.ruleForm.contrastFile = file
    },
    handleRemoveSource () {
      this.ruleForm.sourceFile = null
    },
    handleRemoveContrast () {
      this.ruleForm.contrastFile = null
    },
    async handleSubmit () {
      try {
        await this.$refs.ruleForm.validate()
        this.isloading = true
        this.ruleForm.sourceFile = this.ruleForm.sourceFile.raw
        this.ruleForm.contrastFile = this.ruleForm.contrastFile?.raw
        let data = {}
        if (this.type === 'meddra') {
          data = await medDRAAddCode(this.ruleForm)
        }
        if (this.type === 'whodd') {
          delete this.ruleForm.contrastFile
          data = await whoDDAddCode(this.ruleForm)
        }
        this.isloading = false
        if (data.code === 0) {
          this.visible = false
          this.callback({ done: true })
        }
      } catch (error) { console.log(error) }
    }
  },
  created () {
    this.loadSetInfo()
  }
}
</script>

<style lang="scss" scoped>
.el-form {
	width: 1000px;
	margin: 0 auto;
}

::v-deep .el-upload {
	width: 100%;
}

::v-deep .el-upload-dragger {
	width: 100%;
}

.el-dialog__body {
	height: 90%;
}
</style>