<template>
	<TablePage>
		<template #header>
			<div class="pageHeader">
				<p>whoDD医学词典</p>
				<el-form
					:inline="true"
					:model="form"
					ref="form"
					class="demo-form-inline"
				>
					<el-form-item
						v-if="from === 'dictionary'"
						label="语言"
						prop="language"
					>
						<el-select v-model="form.language" placeholder="请选择语言">
							<el-option
								v-for="item in langList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						v-if="from === 'dictionary'"
						label="版本"
						prop="version"
					>
						<el-select v-model="form.version" placeholder="请选择版本">
							<el-option
								v-for="item in versionList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="药物名称B3" prop="drugName">
						<el-input
							v-model="form.drugName"
							placeholder="输入药物名称B3"
						></el-input>
					</el-form-item>
					<el-form-item label="药物编码" prop="drugCode">
						<el-input
							v-model="form.drugCode"
							placeholder="输入药物编码"
						></el-input>
					</el-form-item>
					<el-form-item>
						<div style="display: flex">
							<el-button type="primary" @click="onSubmit" :loading="isloading"
								>查询</el-button
							>
							<el-button type="default" @click="resetQuery">重置</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</template>
		<template #table>
			<el-table
				ref="tableData"
				:data="tableData"
				height="100%"
				v-loading="isloading"
				:highlight-current-row="from === 'editcode'"
				@row-click="singleElection"
			>
				<el-table-column
					v-if="from === 'editcode'"
					align="center"
					width="55"
					label="选择"
				>
					<template slot-scope="scope">
						<el-radio
							@click.native.prevent="return false"
							class="radio"
							v-model="drugCode"
							:label="scope.row.drugCode"
							>&nbsp;</el-radio
						>
					</template>
				</el-table-column>
				<el-table-column
					v-if="from === 'dictionary'"
					prop="language"
					label="语言"
					width="50"
					align="center"
				>
				</el-table-column>
				<el-table-column
					v-if="from === 'dictionary'"
					prop="version"
					label="版本"
					width="150"
					align="center"
				>
				</el-table-column>
				<el-table-column prop="drugName" label="药物名称B3" align="center">
				</el-table-column>
				<el-table-column
					prop="drugCode"
					label="药物编码"
					width="150"
					align="center"
				>
				</el-table-column>
				<el-table-column prop="ptName" label="首选术语" align="center">
				</el-table-column>
				<el-table-column
					prop="ptCode"
					label="首选术语编码"
					width="150"
					align="center"
				>
				</el-table-column>
				<el-table-column
					prop="atcLevel"
					label="ATC级别"
					width="100"
					align="center"
				>
				</el-table-column>
				<el-table-column prop="atc" label="ATC">
					<template slot-scope="scope">
						<p v-for="(item, index) in scope.row.atc" :key="index">
							{{ item.code }}:{{ item.name }}
						</p>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<template #footer>
			<div class="footer">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="form.page"
					:page-sizes="[20, 100, 300, 500]"
					:page-size="form.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
					:disabled="isloading"
				>
				</el-pagination>
			</div>
		</template>
	</TablePage>
</template>

<script>
import TablePage from '_c/tablePage';
import { getWhoDD, getWhoDDSetInfo } from '_s/medicode.js';
export default {
  name: 'whoddCode',
  props: {
    from: { required: true, type: String },// 从哪个页面打开药物词典
    language: { required: false, type: String },
    version: { required: false, type: String },
    drug_Code: { required: false, type: String } // dialog打开的时候，需要根据药物编码查询后回显
  },
  components: { TablePage },
  data () {
    return {
      form: {
        drugCode: '',
        drugName: '',
        language: '',
        version: '',
        page: 1,
        pageSize: 20
      },
      isloading: false,
      tableData: [],
      total: 0,
      langList: [],
      versionList: [],
      langObj: {},
      drugCode: '',
      atcLoad: false
    }
  },
  methods: {
    async loadSetInfo () {
      const res = await getWhoDDSetInfo()
      this.langObj = res.data.language
      for (let i in this.langObj) {
        this.langList.push({ label: this.langObj[i], value: i })
      }
      const v = res.data.version
      for (let i in v) {
        this.versionList.push({ label: v[i], value: i })
      }
    },
    async loadData () {
      this.isloading = true
      if (this.language) this.form.language = this.language
      if (this.version) this.form.version = this.version
      const { data } = await getWhoDD(this.form)
      this.tableData = data.data.map(i => {
        i.language = this.langObj[i.language]
        i.atc = JSON.parse(i.atc)
        return i
      })
      this.total = data.total
      this.isloading = false
      if (data.data.length === 1) {
        const row = data.data[0]
        this.$refs['tableData'].setCurrentRow(row)
        this.singleElection(row)
      }
    },
    onSubmit () {
      this.form.page = 1
      this.loadData()
    },
    resetQuery () {
      this.$refs.form.resetFields()
      if (this.from === 'dictionary') {
        this.$refs.form.resetFields()
      }
      if (this.from === 'editcode') {
        this.form.drugCode = ''
        this.form.drugName = ''
        this.$emit('changeColum', false, null)
      }
      this.loadData()
    },
    handleSizeChange (val) {
      this.form.pageSize = val
      this.loadData()
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.loadData()
    },
    singleElection (row) {
      if (this.atcLoad) return this.$message.error({ message: '操作太快，请等待' })
      this.atcLoad = true
      this.drugCode = row.drugCode
      const callback = () => {
        this.atcLoad = false
      }
      this.$emit('changeColum', row, callback)
    }
  },
  created () {
    if (this.from === 'dictionary') {
      this.loadSetInfo().then(() => {
        this.loadData()
      })
    }
    if (this.from === 'editcode') {
      if (this.drug_Code) {
        // 根据药物编码查询，然后回显，并且选中唯一的行
        this.form.drugCode = this.drug_Code
      }
      this.loadData()
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
	display: flex;
	justify-content: flex-end;
	padding: 15px 15px 0 0;
}

.pageHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.demo-form-inline {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.el-form-item {
	margin-bottom: 0px;
}
</style>
