import Vue from 'vue'
// 导入组件
import SetPasswordDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个“子类”
const SetPasswordDetailConstructor = Vue.extend(SetPasswordDialog)

let setPasswordInstance = null
/**
 * 文件详情文件 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
export default (obj) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (setPasswordInstance !== null) {
    document.body.removeChild(setPasswordInstance.$el)
  }
  // username 设置密码的账号名
  let { username, isClose } = obj
  return new Promise((reslove) => {
    setPasswordInstance = new SetPasswordDetailConstructor({
      el: document.createElement('div'),
      data () {
        return {
          username,
          isClose
        }
      }
    })
    setPasswordInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res.done && setPasswordInstance !== null) {
        document.body.removeChild(setPasswordInstance.$el)
        setPasswordInstance = null
      }
    }
    document.body.appendChild(setPasswordInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      setPasswordInstance.visible = true //  打开对话框
    })
  })
}
