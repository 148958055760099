import axios from 'axios'
import store from '@/store'
import globalConfig from '@/config/index.js'
import router from '@/router'
import { Message } from 'element-ui'
import qs from 'qs';

// 跳转登录页面重新登录
function redirectLogin () {
  router.push({
    name: 'login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
  setTimeout(() => {
    window.location.reload()
  }, 500);
}

// 请求超时时间
//axios.defaults.timeout = 10000 * 5

// 请求基础 URL
axios.defaults.baseURL = globalConfig.baseContext

// POST 请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 请求拦截器
axios.interceptors.request.use(
  config => {
    const { token } = store.state.user
    if (token && token.access_token) {
      config.headers = { Authorization: token.token_type + ' ' + token.access_token }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


// 响应拦截器
let isredirecting = false
let requests = []
axios.interceptors.response.use(
  response => {
    //如果是下载文件，把文件名返回
    if (response.headers['file-name']) {
      return {
        fileName: decodeURI(response.headers['file-name']), data: response.data
      }
    }
    if (response.headers['content-disposition']) {
      const cd = response.headers['content-disposition']
      const cds = cd.split("utf-8''")
      if (cds.length > 1) {
        const fileName = decodeURIComponent(cds[1])
        return { fileName, data: response.data }
      } else {
        return { fileName: cd.split('=')[1].split(';')[0], data: response.data }
      }
    }
    // 统一处理后台返回code 提示消息
    const { data } = response
    if (data.code === 0) {
      if (data.message) Message.success({ message: data.message, offset: 60 })
    } else {
      if (data.code === 406) { redirectLogin() }
      if (data.message && data.code !== -2) Message.error({ message: data.message, offset: 60 })
    }
    return data
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 404:
          Message.warning('接口不存在，请刷新重试或联系管理员')
          return Promise.resolve(error)
        case 401:
          if (!store.state.user.token) { // 没有token
            redirectLogin()
          }
          // token无效的情况，刷新token
          if (isredirecting) {
            return requests.push(() => {
              axios(error.config)
            })
          }
          isredirecting = true
          return axios({
            method: 'POST',
            url: '/auth/refresh',
            params: { refresh_token: store.state.user.token.refresh_token }
          }).then(resolve => {
            if (resolve.code !== 0) {
              // 刷新token失败
              store.commit('setUserInfo', null)
              redirectLogin()
            }
            // 下面是刷新token成功
            store.commit('setToken', JSON.stringify(resolve.data))
            // 重新发送 刷新token之前的 请求
            requests.forEach(callback => callback())
            requests = []
            return axios(error.config)
          }).catch(reject => {
            return Promise.reject(reject)
          }).finally(() => {
            isredirecting = false
          })
        case 500:
          Message.error('服务异常，请稍后刷新重试或联系管理员')
          return Promise.resolve(error)
        case 502:
          Message.error('服务异常，请稍后刷新重试或联系管理员')
          return Promise.resolve(error)
        default:
          Message.warning(error.response.data.message)
          return Promise.reject(error.response)
      }
    }
  })
/**
 * 封装 get方法 对应 GET 请求
 * @param {string} url 请求url
 * @param {object} params 查询参数
 * @returns {Promise}
 */
export function get (url, data) {
  if (data) {
    data.hash = window.location.hash.replace('#', '')
    url = url + `?${qs.stringify(data)}`
  }
  return new Promise((resolve, reject) => {
    axios.get(url).then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
/**
 * 封装 post 方法，对应 POST 请求
 * @param {string} url 请求url
 * @param {object} data 请求体
 * @param {boolean | undefined} info 请求体是否为 FormData 格式
 * @returns {Promise}
 */
export function post (url, data = {}, info) {
  data.hash = window.location.hash.replace('#', '')
  return new Promise((resolve, reject) => {
    let newData = data
    if (info) {
      //  转formData格式
      newData = new FormData()
      for (const i in data) {
        newData.append(i, data[i])
      }
    }
    axios.post(url, newData).then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
/**
 * 封装带进度条的 post 请求
 * @param {string} url 请求url
 * @param {object} data 请求体
 * @param {funcction} onDownloadProgress 进度回调
 * @param {object} cancelToken 标记正在请求的资源
 * @param {boolean | undefined} info 请求体是否为 FormData 格式
 * @returns {Promise}
 */
export function postProgress (url, data = {}, onDownloadProgress, cancelToken, info,) {
  data.hash = window.location.hash.replace('#', '')
  let newData = data
  if (info) {
    newData = new FormData()
    for (const i in data) newData.append(i, data[i])
  }
  return axios({
    url,
    method: 'POST',
    responseType: "blob",
    data: newData,
    onDownloadProgress,
    cancelToken
  })
}