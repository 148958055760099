import { get, post, postProgress } from './http'
import qs from 'qs'

// 获取文件列表  folderId 文件夹id 默认 0  回收站 -1
export const getFileList = data => get(`/v1/fileStorage/files?${qs.stringify(data)}`)

// 下载文件
export const downloadFile = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/fileStorage/download`, data, onDownloadProgress, cancelToken, true)

// 删除文件到回收站
export const deleteFileByFalse = data => post(`/v1/fileStorage/delete`, data)

// 从回收站恢复文件
export const restoreFileByFalse = data => post(`/v1/fileStorage/restore`, data)

// 获取文件夹
export const getFolderList = data => get(`/v1/fileStorage/folder`, data)

// 日志列表
export const getFileLogList = data => get(`/v1/fileStorage/fileLog`, data)

// 新建文件夹
export const newFolder = data => post(`/v1/fileStorage/newFolder`, data)

// 修改文件夹
export const changeFolder = data => post(`/v1/fileStorage/changeFolder`, data)

// 获取用户组列表
export const getUserGroup = data => get(`/v1/fileStorage/userGroup`, data)

// 新建用户组
export const newUserGroup = data => post(`/v1/fileStorage/newUserGroup`, data, true)

// 修改用户组
export const changeUserGroup = data => post(`/v1/fileStorage/changeUserGroup`, data)

// 权限组-获取用户列表
export const getUserList = data => get(`/v1/getUserList`, data)

// 权限组-获取文件列表
export const getUserFolder = data => get(`/v1/fileStorage/userFolder`, data)

// 权限组-用户绑定用户组
export const saveUserGroup = data => post(`/v1/fileStorage/saveUserGroup`, data)

// 权限组-文件夹绑定用户组
export const saveUserGroupPermission = data => post(`/v1/fileStorage/saveUserGroupPermission`, data)

// 文件夹-文件夹管理员列表
export const getFolderByAdmin = data => get(`/v1/fileStorage/getFolderByAdmin`, data)

// 文件夹-文件夹绑定管理员
export const saveFolderAdmin = data => post(`/v1/fileStorage/saveFolderAdmin`, data)

// 获取预览文件扩展名
export const previewType = data => get(`/v1/fileStorage/previewType?${qs.stringify(data)}`)

// 上传完毕，发起合并文件请求
export const uploadMerge = data => post(`/v1/fileStorage/uploadMerge`, data)

//  重命名文件
export const fileRename = data => post(`/v1/fileStorage/rename`, data)

//  权限组转交
export const transmitGroup = data => post(`/v1/fileStorage/transmitGroup`, data)

//  获取用户组拥有者
export const userGroupOwner = () => get(`/v1/fileStorage/userGroupOwner`)

// 获取下载任务列表
export const downloadTask = data => get(`/v1/fileStorage/downloadTask?${qs.stringify(data)}`)

//  新建下载任务
export const newDownloadTask = data => post(`/v1/fileStorage/newDownloadTask`, data)

//  下载打包文件
export const taskFileDownload = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/fileStorage/taskFileDownload`, data, onDownloadProgress, cancelToken, true)

//  导出文件
export const handleFileStorageExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/fileStorage/folderExport`, data, onDownloadProgress, cancelToken, true)