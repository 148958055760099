<template>
	<el-dialog :visible.sync="visible" fullscreen>
		<el-tabs>
			<el-tab-pane label="报告">
				<el-descriptions class="margin-top" title="报告信息" :column="2">
					<el-descriptions-item label="沟通主题">{{
						viewObj.title
					}}</el-descriptions-item>
					<el-descriptions-item label="联系方式">{{
						viewObj.way
					}}</el-descriptions-item>
					<el-descriptions-item label="实际开始时间">{{
						viewObj.start_date
					}}</el-descriptions-item>
					<el-descriptions-item label="实际结束时间">{{
						viewObj.end_date
					}}</el-descriptions-item>
					<el-descriptions-item label="联系时长">{{
						viewObj.hours
					}}</el-descriptions-item>
					<el-descriptions-item label="内容总结">{{
						viewObj.hours
					}}</el-descriptions-item>
					<el-descriptions-item label="达成共识">{{
						viewObj.consensus
					}}</el-descriptions-item>
					<el-descriptions-item label="需跟进的工作">{{
						viewObj.follow_work
					}}</el-descriptions-item>
					<el-descriptions-item label="协同沟通人员">{{
						viewObj.collaborative_personnel_label
					}}</el-descriptions-item>
					<el-descriptions-item label="中心参与人员">{{
						viewObj.hospital_participants_label
					}}</el-descriptions-item>
					<el-descriptions-item label="审批状态">{{
						viewObj.status === 0
							? '未提交'
							: viewObj.status === 1
							? '审批中'
							: viewObj.status === 2
							? '通过'
							: viewObj.status === 3
							? '驳回'
							: ''
					}}</el-descriptions-item>
				</el-descriptions>
			</el-tab-pane>
			<el-tab-pane label="查看审批">
				<el-empty
					description="暂无数据"
					v-if="resultList.length < 1"
				></el-empty>
				<el-card class="box-card" v-for="card in resultList" :key="card.title">
					<h4>{{ card.title }}</h4>
					<p>{{ card.userName }}&nbsp;{{ card.sumbitTime | dateFormat }}</p>
					<br />
					<el-table :data="card.table" border style="width: 100%">
						<el-table-column prop="node" label="审批节点"> </el-table-column>
						<el-table-column prop="user_name" label="处理人"> </el-table-column>
						<el-table-column label="操作时间">
							<template slot-scope="scope">
								{{ scope.row.createdAt | dateFormat }}
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审批结果">
							<template slot-scope="scope">
								<p class="markerLine" v-if="scope.row.status === 0">
									<span class="marker"> </span>
									未处理
								</p>
								<p class="markerLine" v-if="scope.row.status === 2">
									<span class="marker-green"> </span>
									通过
								</p>
								<p class="markerLine" v-if="scope.row.status === 3">
									<span class="marker-red"> </span>
									驳回
								</p>
							</template>
						</el-table-column>
						<el-table-column prop="remarks" label="意见"> </el-table-column>
					</el-table>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="审批" v-if="row.status == 1">
				<el-form :model="approval" :rules="approvalForm" ref="approvalForm">
					<el-form-item label="任务：" label-width="120px">
						{{ approval.title }}
					</el-form-item>
					<el-form-item
						prop="approval_status"
						label="提交审批："
						label-width="120px"
					>
						<el-select
							v-model="approval.approval_status"
							placeholder="请选择审批结果"
							style="width: 100%"
						>
							<el-option label="通过" :value="2"></el-option>
							<el-option label="驳回" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="审批意见：" label-width="120px">
						<el-input v-model="approval.remark" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item class="shenpi">
						<el-button type="primary" @click="sumbitApproval"
							>提交审批</el-button
						>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
	</el-dialog>
</template>

<script>
import {
  reportdetail,
  reportapprovalDetail,
  approval
} from '_s/project';
export default {
  data () {
    return {
      viewObj: {},
      resultList: [],
      visible: false,
      patientList: [],
      approval: {
        title: '',
        approval_status: '',
        remark: ''
      },
      approvalForm: {
        approval_status: [
          { required: true, message: '请选择审批结果', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async initData () {
      const { data } = await reportdetail({ id: this.row.id, project_id: localStorage.project_id, hospital_id: localStorage.hospital_id })
      this.viewObj = data
      const { data: resultList } = await reportapprovalDetail({ project_id: localStorage.project_id, cid: this.row.id, hospital_id: localStorage.hospital_id })
      this.resultList = resultList
    },
    async sumbitApproval () {
      try {
        await this.$refs.approvalForm.validate()
        const data = await approval(this.approval)
        if (data.code === 0) {
          this.visible = false
          this.callback({ done: true })
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  created () {
    this.initData()
    this.approval.title = this.row.title
    this.approval.cid = this.row.id
    this.approval.workflow_id = this.row.workflow_id
    this.approval.project_id = localStorage.project_id

  }
}
</script>

<style lang="scss" scoped>
.el-card {
	margin-bottom: 15px;
}
.shenpi {
	display: flex;
	justify-content: space-evenly;
}
</style>