<template>
	<!-- 查看文件详情对话框 -->
	<el-dialog
		class="file-info-dialog"
		title="文件详情"
		:visible.sync="visible"
		:close-on-click-modal="false"
		width="550px"
		@close="handleDialogClose"
	>
		<img
			class="file-img"
			:src="$file2.setFileImg(fileInfo)"
			:title="`${fileInfo.is_dir ? '' : '点击预览'}`"
		/>
		<el-form
			class="file-info-form"
			:model="fileInfo"
			ref="fileInfoForm"
			label-width="82px"
			label-position="right"
			label-suffix="："
			size="small"
		>
			<el-form-item label="文件名" prop="file_name">
				<el-input
					:value="$file2.getFileNameComplete(fileInfo)"
					readonly
				></el-input>
			</el-form-item>
			<el-form-item :label="fileType === 6 ? '原路径' : '路径'" prop="file_path">
				<el-input :value="fileInfo.file_path" readonly></el-input>
			</el-form-item>
			<el-form-item label="类型" prop="extend">
				<el-input :value="$file2.getFileType(fileInfo)" readonly></el-input>
			</el-form-item>
			<el-form-item label="大小" prop="file_size">
				<el-input
					:value="
						fileInfo.is_dir === 0
							? $file2.calculateFileSize(fileInfo.file_size)
							: ''
					"
					readonly
				></el-input>
			</el-form-item>
			<el-form-item label="创建人" prop="user_name">
				<el-input :value="fileInfo.user_name" readonly></el-input>
			</el-form-item>
			<el-form-item label="创建时间" prop="created_at">
				<el-input :value="fileInfo.created_at | dateFormat3" readonly></el-input>
			</el-form-item>
			<el-form-item label="操作用户" prop="deleteName" v-if="fileType === 6">
				<el-input :value="fileInfo.deleteName" readonly></el-input>
			</el-form-item>
			<el-form-item label="删除日期" prop="delete_date" v-if="fileType === 6">
				<el-input :value="fileInfo.delete_date | dateFormat3" readonly></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleDialogClose">关 闭</el-button>
		</div>
	</el-dialog>
</template>

<script>
import router from '@/router'

export default {
  name: 'showFileDetailDialog',
  data () {
    return {
      visible: false //  对话框是否可见
    }
  },
  computed: {
    // 左侧菜单选中的文件类型
    fileType () {
      return router.currentRoute.query.fileType
        ? Number(router.currentRoute.query.fileType)
        : 0
    },
    // 路由名称
    routeName () {
      return router.currentRoute.name
    }
  },
  methods: {
    /**
     * 查看文件详情对话框 | 对话框关闭的回调
     * @description 关闭对话框
     */
    handleDialogClose () {
      this.visible = false
      this.$refs.fileInfoForm.resetFields()
      this.callback('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.file-img {
	display: block;
	margin: 0 auto 8px auto;
	max-width: 120px;
	width: 40%;
	height: auto;
}

.el-form.file-info-form {
	.el-form-item {
		margin-bottom: 16px;

		.el-input__inner {
			border: none;
			font-size: 14px;
		}

		&.form-item-end-time {
			.el-form-item__content {
				display: flex;
				align-items: center;

				.el-input {
					width: 141px;

					.el-input__inner {
						padding-right: 0;
					}
				}

				.status-icon {
					font-size: 14px;
				}

				.el-icon-warning {
					color: #e6a23c;
				}

				.el-icon-time {
					color: #67c23a;
				}
			}
		}
	}
}
</style>
