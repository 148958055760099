import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=75e8083e&scoped=true&"
import script from "./Dialog.vue?vue&type=script&lang=js&"
export * from "./Dialog.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./Dialog.vue?vue&type=style&index=1&id=75e8083e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75e8083e",
  null
  
)

export default component.exports