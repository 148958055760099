import { post, get } from './http'
import qs from 'qs'

// 登录功能
export const login = data => post(`/auth/login?${qs.stringify(data)}`)

// 用户基本信息
export const userInfo = data => post('/v1/getUserInfo', data)

// 更新密码
export const changePassword = data => post(`/v1/changePassword?${qs.stringify(data)}`)

// 更新密码
export const logout = () => post(`/auth/logout`)

// 获取钉钉KEY
export const dingAppKey = () => get(`/auth/dingAppKey`)