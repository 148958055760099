<template>
	<TablePage title="报价模板">
		<template #header>
			<el-button
				v-if="!type"
				type="primary"
				@click="handleAdd"
				icon="el-icon-plus"
				class="headerBtn"
				>新建报价模板</el-button
			>
		</template>
		<template #table>
			<el-table
				ref="wtData"
				:data="wtData"
				height="100%"
				v-loading="isloading"
				@row-click="singleElection"
			>
				<el-table-column v-if="type" align="center" width="55" label="选择">
					<template slot-scope="scope">
						<el-radio
							@click.native.prevent="return false"
							class="radio"
							v-model="selectID"
							:label="scope.row.id"
							>&nbsp;</el-radio
						>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="模板名" align="center">
				</el-table-column>
				<el-table-column prop="user_name" label="创建人" align="center">
				</el-table-column>
				<el-table-column label="状态" prop="user_name" align="center">
					<template slot-scope="scope">
						<el-tag type="info" v-if="scope.row.state === 0" effect="plain">
							未发布
						</el-tag>
						<el-tag type="warning" v-if="scope.row.state === 1" effect="plain">
							已保存
						</el-tag>
						<el-tag type="success" v-if="scope.row.state === 2" effect="plain">
							已发布
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" align="center">
					<template slot-scope="scope">
						{{ scope.row.createdAt | dateFormat }}
					</template>
				</el-table-column>
				<el-table-column v-if="!type" label="操作" align="center" width="200">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="handleSave(scope.row)"
							>设置</el-button
						>
						<el-button
							size="mini"
							type="warning"
							@click="handleUpdate(scope.row)"
							>修改模板名</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog
				:close-on-click-modal="false"
				:close-on-press-escape="false"
				:show-close="false"
				title="报价模板"
				:visible.sync="centerDialogVisible"
				width="30%"
				center
			>
				<el-input v-model="templateName" placeholder="请输入模板名"></el-input>
				<span slot="footer" class="dialog-footer">
					<el-button @click="handleCloseDialog">取 消</el-button>
					<el-button type="primary" @click="handleSubmit">提 交</el-button>
				</span>
			</el-dialog>
		</template>
		<template #footer> </template>
	</TablePage>
</template>

<script>
import TablePage from '_c/tablePage'
import { getListByTemplate, addTemplate, updateTemplate } from '_s/crm';
export default {
  props: {
    type: {}
  },
  name: 'myWorkTime',
  components: {
    TablePage
  },
  data () {
    return {
      centerDialogVisible: false,
      wtData: [],
      isloading: false,
      time: '',
      templateName: '',
      templageId: '',
      selectID: ''
    }
  },
  methods: {
    handleSave (row) {
      this.$router.push({ path: '/crm/templates/add', query: { id: row.id, templateName: row.name } })
    },
    async initData () {
      this.isloading = true
      const { data } = await getListByTemplate()
      if (this.type) {
        this.wtData = data.data.filter(v => v.state === 2)
      } else {
        this.wtData = data.data
      }

      this.isloading = false
    },
    handleCloseDialog () {
      this.templateName = ''
      this.centerDialogVisible = false
      this.templageId = ''
    },
    async handleSubmit () {
      let data = ''
      if (this.templageId) { //修改
        data = await updateTemplate({ name: this.templateName, id: this.templageId })
      } else {// 新增
        data = await addTemplate({ name: this.templateName })
      }
      if (data.code === 0) {
        this.initData()
        this.centerDialogVisible = false
      }
    },
    handleAdd () {
      this.centerDialogVisible = true
      this.templateName = ''
    },
    handleUpdate (row) {
      this.centerDialogVisible = true
      this.templateName = row.name
      this.templageId = row.id
    },
    singleElection (row) {
      this.selectID = row.id
      this.$emit('changeColum', row)
    }
  },
  created () {
    this.initData()
  }
}
</script>
<style lang="scss" scoped>
.headerBtn {
	float: right;
}

::v-deep .el-range-separator {
	padding: 0 !important;
}

.el-button {
	margin-left: 15px;
}
</style>
