import renderPdf from "./pdf";
import renderImage from "./image";
import renderText from "./text";

// 假装构造一个vue的包装，让上层统一处理销毁和替换节点
const VueWrapper = (el) => ({
  $el: el,
  $destroy () {
    // 什么也不需要 nothing to do
  },
});

const handlers = [
  {
    accepts: ["pdf"],
    handler: async (buffer, target) => {
      return renderPdf(buffer, target);
    },
  },
  {
    accepts: ["gif", "jpg", "jpeg", "bmp", "tiff", "tif", "png", "svg"],
    handler: async (buffer, target) => {
      return renderImage(buffer, target);
    },
  }, {
    accepts: ["txt", "json", "js", "css", "java", "py", "html", "jsx", "ts", "tsx", "xml", "md", "log",
    ],
    handler: async (buffer, target) => {
      return renderText(buffer, target);
    },
  },
  {
    accepts: ["error"],
    handler: async (buffer, target, type) => {
      target.innerHTML = `<div style="position: relative;top: 35%;">
      <div style="text-align: center; margin-top: 80px">不支持.${type}格式的在线预览，请下载后预览或转换为支持的格式</div>
      <div style="text-align: center">支持 .mp4, doc, docx, xls, xlsx, pptx, pdf, 以及代码文件，纯文本格式和各种图片格式的在线预览</div>
      </div>`;
      return VueWrapper(target);
    },
  },
];

export default handlers.reduce((result, { accepts, handler }) => {
  accepts.forEach((type) => result[type] = handler);
  return result;
}, {});
