import Vue from 'vue'
import dialog from './Dialog.vue'
const dialogConstructor = Vue.extend(dialog)

let dialogInstance = null
export default (data) => {
  if (dialogInstance !== null) {
    document.body.removeChild(dialogInstance.$el)
  }
  const { row, type, tabs, view, approvalType, status } = data
  return new Promise((reslove) => {
    dialogInstance = new dialogConstructor({
      el: document.createElement('div'),
      data () {
        return { row, type, tabs, view, approvalType, status }
      }
    })
    dialogInstance.callback = (res) => {
      reslove(res)
      if (res.done && dialogInstance !== null) {
        document.body.removeChild(dialogInstance.$el)
        dialogInstance = null
      }
    }
    document.body.appendChild(dialogInstance.$el)
    Vue.nextTick(() => {
      dialogInstance.visible = true
    })
  })
} 