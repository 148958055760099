<template>
	<el-dialog
		:visible.sync="visible"
		fullscreen
		:modal="false"
		:destroy-on-close="true"
		:show-close="false"
		center
	>
		<meddraCode
			:from="from"
			:version="version"
			@changeColum="changeColum"
			:language="language"
		></meddraCode>
		<span slot="footer" class="dialog-footer">
			<el-button class="btn" @click="visible = false">取 消</el-button>
			<el-checkbox
				v-if="from === 'editcode'"
				v-model="batchUpdate"
				label="批量更新"
				border
			></el-checkbox>
			<el-button type="primary" @click="handleSubmit">提 交 选 择</el-button>
		</span>
	</el-dialog>
</template>

<script>
import meddraCode from '_c/medicode/meddraCode.vue';
import { updateMedDRAData } from '_s/medicode';
export default {
  data () {
    return {
      visible: false,
      batchUpdate: false,
      lltCode: '',
      row: {}
    }
  },
  components: {
    meddraCode
  },
  methods: {
    changeColum (row) {
      this.lltCode = row.lltCode
      this.row = row
    },
    async handleSubmit () {
      if (!this.lltCode) return this.$message.error({ message: '请选择低位语编码' })
      if (this.from === 'editcode') {
        const data = {
          id: this.id,
          taskId: this.taskId,
          language: this.language,
          version: this.version,
          name: this.name,
          otherName: this.otherName,
          batchUpdate: Number(this.batchUpdate),
          lltCode: this.lltCode
        }
        const res = await updateMedDRAData(data)
        if (res.code === 0) {
          this.visible = false
          this.callback({ done: true })
        }
      }
      if (this.from === 'codelibrary') {
        this.visible = false
        this.callback({ done: true, data: this.row })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
	padding: 5px;
}

::v-deep .el-dialog__body {
	height: 94%;
}

::v-deep .el-dialog__footer {
	padding: 0;
}

::v-deep .el-checkbox,
.btn {
	margin-right: 10px !important;
}
</style>