<template>
	<el-dialog
		title="新建"
		:visible.sync="visible"
		center
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
	>
		<el-form
			:model="ruleForm"
			:rules="rules"
			ref="ruleForm"
			label-width="100px"
			class="demo-ruleForm"
		>
			<el-form-item label="语言" prop="language">
				<el-select
					style="width: 100%"
					v-model="ruleForm.language"
					placeholder="请选择语言"
				>
					<el-option
						v-for="item in langList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="版本" prop="version">
				<el-select
					style="width: 100%"
					v-model="ruleForm.version"
					placeholder="请选择版本"
				>
					<el-option
						v-for="item in versionList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="匹配名称" prop="name">
				<el-input v-model="ruleForm.name" placeholder="输入匹配名称"></el-input>
			</el-form-item>
			<el-form-item v-if="type === 'meddra'" label="低位语" prop="lltValue">
				<el-input
					disabled
					placeholder="请选择低位语"
					v-model="ruleForm.lltValue"
					class="input-with-select"
				>
					<template #suffix>
						<el-button
							type="primary"
							icon="el-icon-search"
							@click="handelSelectLltCode"
							>打开MedDRA医学辞典</el-button
						>
					</template>
				</el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="primary" @click="handleSubmit">新 建</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { getMedDRASetInfo, medDRAAddLibrary, getWhoDDSetInfo, WhoDDAddForm } from '_s/medicode.js';
export default {
  data () {
    return {
      visible: false,
      ruleForm: {
        lltValue: ''
      },
      langList: [],
      versionList: [],
      langObj: {},
      row: {},
      rules: {
        name: [{ required: true, message: '请输入匹配名称', trigger: 'blur' }],
        language: [{ required: true, message: '请选择语言', trigger: 'change' }],
        version: [{ required: true, message: '请选择版本', trigger: 'change' }],
        lltValue: [{ required: true, message: '请选择低位语', trigger: 'blur' }],
      }
    }
  },
  methods: {
    async loadSetInfo () {
      let res = ''
      if (this.type === 'meddra') res = await getMedDRASetInfo()
      if (this.type === 'whodd') res = await getWhoDDSetInfo()
      this.langObj = res.data.language
      for (let i in this.langObj) {
        this.langList.push({ label: this.langObj[i], value: i })
      }
      const v = res.data.version
      for (let i in v) {
        this.versionList.push({ label: v[i], value: i })
      }
    },
    handelSelectLltCode () {
      this.$medicodeDialog.meddraEditCode({ id: null, taskId: null, language: null, version: '', name: null, otherName: null }, 'codelibrary').then(res => {
        if (res.done) {
          this.row = res.data
          this.ruleForm.lltValue = `${res.data.lltCode}：${res.data.lltName}`
        }
      })
    },
    async handleSubmit () {
      try {
        await this.$refs.ruleForm.validate()
        if (this.type === 'meddra') {
          this.ruleForm['lltCode'] = this.row.lltCode
          const res = await medDRAAddLibrary(this.ruleForm)
          if (res.code === 0) {
            this.visible = false
            this.callback({ done: true })
          }
        }
        if (this.type === 'whodd') {
          delete this.ruleForm.lltValue
          const res = await WhoDDAddForm(this.ruleForm)
          if (res.code === 0) {
            this.visible = false
            this.callback({ done: true })
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  created () {
    this.loadSetInfo()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__suffix {
	right: 0;
}
</style>