export default {
  path: '/finance',
  component: () => import(/* webpackChunkName: 'finance' */'@/views/finance/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '/finance/milestonePaymentInformation',
      name: 'milestonePaymentInformation',
      component: () => import(/* webpackChunkName: 'finance' */'@/views/finance/milestonePaymentInformation')
    },
    {
      path: '/finance/businessUnitInfo',
      name: 'businessUnitInfo',
      component: () => import(/* webpackChunkName: 'finance' */'@/views/finance/businessUnitInfo')
    },
  ]
}