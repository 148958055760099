import store from '@/store'
import storage from './storage'
import storage3 from './storage3'
import medicode from './medicode'
import crm from './crm'
import ctms from './ctms'
import project from './project'
import system from './system'
import worktime from './worktime'
import login from './login'
import Vue from 'vue'
import VueRouter from 'vue-router'
import meetingRoom from './meetingRoom';
import worktimeDingDing from './worktimeDingDing';
import hospital from './hospital'
import guideline from './guideline'
import express from './express'
import talent from './talent';
import statistic from './statistic'
import knowledge from './knowledge'
import finance from './finance'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'homeLayout' */'@/views/home/layout/index'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'workbench',
        component: () => import(/* webpackChunkName: 'workbench' */'@/views/home/workbench/newIndex')
      }
    ]
  },
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: 'homeLayout' */'@/views/homeNew/layout/index'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: 'dashboard' */'@/views/homeNew/workbench/index')
      }
    ]
  }
  , login
  , worktime
  , system
  , project
  , ctms
  , storage
  , storage3
  , medicode
  , crm
  , meetingRoom
  , ...worktimeDingDing
  , hospital
  , guideline
  , express
  , talent
  , statistic
  , knowledge
  , finance
  , {
    path: '*',
    name: 'error-page',
    component: () => import(/* webpackChunkName:'error_page' */ '@/views/error-page/index')
  }
]
const router = new VueRouter({
  routes
})
// 全局路由守卫
//let loadingInstance = null
router.beforeEach((to, from, next) => {
  // 判断 to 路由是否需要进行身份认证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user.token) {
      return next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }
  next()
})
router.afterEach(() => {
  //if (loadingInstance) loadingInstance.close()
})
router.onError((error) => {
  const pattern = /Loading chunk +/g;
  const pattern1 = /Unexpected token /g;
  // const isChunkLoadFailed = error.message.match(pattern) ||  error.message.match(pattern1) ;
  if (error.message.match(pattern) || error.message.match(pattern1)) {
    window.location.reload();
    // router.replace(router.history.pending.fullPath);
  } else {
    console.log(error)
  }
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
