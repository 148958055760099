import Vue from 'vue'
// 导入组件
import DepartmentMemberFileDialog from './Dialog.vue'
// 使用基础 Vue 构造器，创建一个 "子类"
const DepartmentMemberFileConstructor = Vue.extend(DepartmentMemberFileDialog)

let departmentMemberFileInstance = null
/**
 * 初始化下载文件进度条
 * @param {object | array} fileInfo 文件信息
 */
const initInstanceFileDetail = (fileInfo, columnOptions) => {
  departmentMemberFileInstance = new DepartmentMemberFileConstructor({
    el: document.createElement('div'),
    data () {
      return {
        fileInfo,
        columnOptions
      }
    }
  })
}
/**
 * 文件详情文件 Promise 函数
 * @returns {Promise} 抛出确认和取消回调函数
 */
const departmentMemberFileDialog = (obj, obj1) => {
  // 非首次调用服务时，在 DOM 中移除上个实例
  if (departmentMemberFileInstance !== null) {
    document.body.removeChild(departmentMemberFileInstance.$el)
  }
  let { fileInfo } = obj
  let { columnOptions } = obj1
  return new Promise((reslove) => {
    initInstanceFileDetail(fileInfo, columnOptions)
    departmentMemberFileInstance.callback = (res) => {
      reslove(res)
      // 服务取消时卸载 DOM
      if (res === 'cancel' && departmentMemberFileInstance !== null) {
        document.body.removeChild(departmentMemberFileInstance.$el)
        departmentMemberFileInstance = null
      }
    }
    document.body.appendChild(departmentMemberFileInstance.$el) //  挂载 DOM
    Vue.nextTick(() => {
      departmentMemberFileInstance.visible = true //  打开对话框
    })
  })
}

export default departmentMemberFileDialog
